import React from "react";
import IntlMessage from "../components/util-components/IntlMessage";
import utils from "../utils";
import {useTranslation} from "react-i18next";

export const setLocale = (localeKey, isLocaleOn = true, alt) =>
    isLocaleOn ? <IntlMessage id={localeKey} fallback={alt} /> : localeKey.toString();

export const catchErrorLocale = (e) => {
    const text = e.toString();
    if (utils.getCurrentDomainAndProtocol() === "http://localhost:8077") {
        console.log(text.slice(text.indexOf("Missing"), text.indexOf(".")))
    }
}

export const UseLocale = (key) => {
    const { t } = useTranslation()

    return key ? t(key) : null;
}

