import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'crm-proxy';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/proxy/order/list`;
export const UNAUTHENTICATED_ENTRY = '/login'

export const USER_THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'ru',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#f3f6f6',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false,
	loading: false,
	user: {
		uid: "",
		name: "",
		uiLanguage: "ru",
		roles: [],
		ticketDepartments: []
	}
};
