import React from "react";
import axios from "axios"
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import {Tooltip} from "antd";
import {AUTH_USER} from "constants/AuthConstant";

import apiService from "../services/ApiService";
import {dataTrafficUnits} from "../constants/crm/dropdown";

class Utils {

	constructor() {
		this.moment = extendMoment(Moment);
		return this
	}

	/**
	 * Get first character from first & last sentences of a username
	 * @param {String} name - Username
	 * @return {String} 2 characters string
	 */
	static getNameInitial(name) {
		let initials = name.match(/\b\w/g) || [];
		return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
	}

	/**
	 * Get current path related object from userProfile permissions
	 * @param {object} userPermissions
	 * @param {Array} array of permissions
	 * @return {boolean} true or false access to actions
	 */

	static getAccessPermission = (userPermissions, permissions) => {
		for (let p in userPermissions) {
			if(permissions.includes(p) && userPermissions[p] === 'ALLOWED'){
				return true
			}

			// return permissions.includes(p)
		}
	}

	/**
	 * Get current path related object from userProfile department code
	 * @param {object} userDeps
	 * @param {Array} array of deps
	 * @return {boolean} true or false access to actions
	 */

	static getAccessDepartment = (userDeps, deps) => {
		if(userDeps && deps){
			return userDeps.some(el => deps.includes(el))
		}else {
			return false
		}
	}

	/**
	 * Get current path related object from Navigation Tree
	 * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
	 * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
	 * @return {Object} object that contained the path string
	 */
	static getRouteInfo(navTree, path) {
		if (navTree.path === path) {
			return navTree;
		}

		let route;
		for (let p in navTree) {
			if (navTree.hasOwnProperty(p) && typeof navTree[p] === 'object') {

				route = this.getRouteInfo(navTree[p], path);

				if (navTree[p].hasOwnProperty("path")) {
					// Verify what the current route if got route undefined;
					const keysPath = path.split("/").slice(2, 4)
					const isRoute = keysPath.some((el) => el!=="list" && el!=="proxy" && el!=="orderSource" && el!=="clients" && (navTree[p].path?.split("/").includes(el)));
					if(isRoute){
						return navTree[p]
					}
				}
				if (route) {
					return route;
				}
			}
		}
		return route
	}

	/**
	 * Get accessible color contrast
	 * @param {String} hex - Hex color code e.g '#3e82f7'
	 * @return {String} 'dark' or 'light'
	 */
	static getColorContrast(hex){
		const threshold = 130;
		const hRed = hexToR(hex);
		const hGreen = hexToG(hex);
		const hBlue = hexToB(hex);
		function hexToR(h) {return parseInt((cutHex(h)).substring(0,2),16)}
		function hexToG(h) {return parseInt((cutHex(h)).substring(2,4),16)}
		function hexToB(h) {return parseInt((cutHex(h)).substring(4,6),16)}
		function cutHex(h) {return (h.charAt(0) === '#') ? h.substring(1,7):h}
		const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
		if (cBrightness > threshold){
			return 'dark'
		} else {
			return 'light'
		}
	}

	/**
	 * Darken or lighten a hex color
	 * @param {String} color - Hex color code e.g '#3e82f7'
	 * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
	 * @return {String} Darken or lighten color
	 */
	static shadeColor(color, percent) {
		let R = parseInt(color.substring(1,3),16);
		let G = parseInt(color.substring(3,5),16);
		let B = parseInt(color.substring(5,7),16);
		R = parseInt(R * (100 + percent) / 100);
		G = parseInt(G * (100 + percent) / 100);
		B = parseInt(B * (100 + percent) / 100);
		R = (R<255)?R:255;
		G = (G<255)?G:255;
		B = (B<255)?B:255;
		const RR = ((R.toString(16).length === 1) ? `0${R.toString(16)}` : R.toString(16));
		const GG = ((G.toString(16).length === 1) ? `0${G.toString(16)}` : G.toString(16));
		const BB = ((B.toString(16).length === 1) ? `0${B.toString(16)}` : B.toString(16));
		return `#${RR}${GG}${BB}`;
	}

	/**
	 * Convert RGBA to HEX
	 * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
	 * @return {String} HEX color
	 */
	static rgbaToHex(rgba) {
		const trim = str => (str.replace(/^\s+|\s+$/gm,''))
		const inParts = rgba.substring(rgba.indexOf("(")).split(","),
			r = parseInt(trim(inParts[0].substring(1)), 10),
			g = parseInt(trim(inParts[1]), 10),
			b = parseInt(trim(inParts[2]), 10),
			a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
		const outParts = [
			r.toString(16),
			g.toString(16),
			b.toString(16),
			Math.round(a * 255).toString(16).substring(0, 2)
		];

		outParts.forEach(function (part, i) {
			if (part.length === 1) {
				outParts[i] = '0' + part;
			}
		})
		return (`#${outParts.join('')}`);
	}

	/**
	 * Returns either a positive or negative
	 * @param {Number} number - number value
	 * @param {any} positive - value that return when positive
	 * @param {any} negative - value that return when negative
	 * @return {any} positive or negative value based on param
	 */
	static getSignNum(number, positive, negative) {
		if (number > 0) {
			return positive
		}
		if (number < 0) {
			return negative
		}
		return null
	}

	/**
	 * Returns either ascending or descending value
	 * @param {Object} a - antd Table sorter param a
	 * @param {Object} b - antd Table sorter param b
	 * @param {String} key - object key for compare
	 * @return {any} a value minus b value
	 */
	static antdTableSorter(a, b, key) {
		if(typeof a[key] === 'number' && typeof b[key] === 'number') {
			return a[key] - b[key]
		}

		if(typeof a[key] === 'string' && typeof b[key] === 'string') {
			a = a[key].toLowerCase();
			b = b[key].toLowerCase();
			return a > b ? -1 : b > a ? 1 : 0;
		}
		return
	}

	/**
	 * Filter array of object
	 * @param {Array} list - array of objects that need to filter
	 * @param {String} key - object key target
	 * @param {any} value  - value that excluded from filter
	 * @return {Array} a value minus b value
	 */
	static filterArray(list, key, value) {
		let data = list
		if(list) {
			data = list.filter(item => item[key] === value)
		}
		return data
	}

	/**
	 * Remove object from array by value
	 * @param {Array} list - array of objects
	 * @param {String} key - object key target
	 * @param {any} value  - target value
	 * @return {Array} Array that removed target object
	 */
	static deleteArrayRow(list, key, value) {
		let data = list
		if(list) {
			data = list.filter(item => item[key] !== value)
		}
		return data
	}

	/**
	 * Wild card search on all property of the object
	 * @param {Number | String} input - any value to search
	 * @param {Array} list - array for search
	 * @return {Array} array of object contained keyword
	 */
	static wildCardSearch(list, input) {
		const searchText = (item) => {
			for (let key in item) {
				if (item[key] == null) {
					continue;
				}
				if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
					return true;
				}
			}
		};
		list = list.filter(value => searchText(value));
		return list;
	}

	/**
	 * Get Breakpoint
	 * @param {Object} screens - Grid.useBreakpoint() from antd
	 * @return {Array} array of breakpoint size
	 */
	static getBreakPoint(screens) {
		let breakpoints = []
		for (const key in screens) {
			if (screens.hasOwnProperty(key)) {
				const element = screens[key];
				if (element) {
					breakpoints.push(key)
				}
			}
		}
		return breakpoints
	}

	/**
	 * Check role access
	 * @param {Array<String>} allowRoles - allowed role (["READ","SHOW", "WRITE"])
	 * @param {Array<String>} userRoles - allowed role (["READ","SHOW"])
	 * @return {Boolean} if user roles is contains return TRUE
	 */
	static roleAccess(allowRoles= [], userRoles= []) {
		try {
			userRoles.forEach((role) => {
				if (allowRoles.includes(role)) {
					throw Object.assign({message: "break"})
				}
			})
		} catch(e) {
			if (e?.message === "break") {
				return true
			}
		}
		return false
	}

	/**
	 * Check user access
	 * @param {Object} user data - (["READ","SHOW", "WRITE"])
	 * @param {Array<String>} allowRoles - allowed role (["READ","SHOW"])
	 * @param {Object} permissions - has property ALLOWED(["READ","SHOW"])
	 * @return {Boolean} if contains return isAccess - true
	 */

	static userAccess(userProfile = {}, allowRoles= [], permissions={}) {
		let isAccess = false
		try {
			for (let p in userProfile.permissions) {
				if (permissions.includes(p) && userProfile.permissions[p] === "ALLOWED") {
					return isAccess = true
				}
			}

			userProfile.roles.forEach((role) => {
				if(allowRoles.includes(role)){
					return isAccess = true
				}
			})
		} catch(e) {
			return console.log("user access has error: ", e)
		}

		return isAccess
	}

	/**
	 * Clear browser cache
	 */
	static clearBrowserCache() {

		const clearCacheOk = (fileName, response) => {
			console.log(`Cache file '${fileName}' is ${response?"deleted":"not deleted!"}`)
		}

		try {
			console.log(`Clear browser cache....`)
			if ('caches' in window) {
				caches.keys().then((names) => {
					if (names.length > 0) {
						names.forEach(name => {
							caches.delete(name).then(clearCacheOk.bind(this, name)).catch((error) => {
								console.error("browser cache: ERROR", error)
							});
						})
					} else {
						console.log("No find caches for remove!");
					}
				});
			} else {
				console.warn("No find caches in window!", window);
			}
		} catch(error) {
			console.warn(`Error on browser clear cache!`, error)
		}
	}

	/**
	 * Clear serviceWorker cache
	 */
	static clearServiceWorkerCache() {

		const clearCacheOk = (fileName, response) => {
			console.log(`ServiceWorker cache file '${fileName}' is ${response?"deleted":"not deleted!"}`)
			if (!response){
				console.log("Cache is not deleted, window will be reload")
				window.location.reload()
			}
		}

		try {
			console.log(`Clear serviceWorker cache....`)
			if ('serviceWorker' in navigator) {
				caches.keys().then(function (cacheNames) {
					if (cacheNames.length > 0) {
						cacheNames.forEach(function (name) {
							caches.delete(name).then(clearCacheOk.bind(this, name)).catch((error) => {
								console.error("serviceWorker cache: ERROR", error)
							});
						});
					} else {
						console.warn("No find serviceWorker caches for remove!");
					}
				});
			} else {
				console.warn("No find serviceWorker in navigator!", navigator);
			}
		} catch(error) {
			console.warn(`Error on serviceWorker clear cache!`, error)
		}
	}

	/**
	 * Clear sessionStorage and localStorage data
	 */

	static getToken = async () => {
		if(window?.location?.pathname !== '/auth/login'){
			return await axios.get('/api/users/authUser').then((res) => {
				if(res.data){
					return {
						token: (new Date().getTime()),
						user: res.data
					}
				}else {
					return null
				}
			})
		}
	}

	static clearSessionAndLocalStorage = async () => {
		try{
			const {token, user} = await this.getToken()
			Object.keys(localStorage).forEach(k => {
				if(k === "auth_token" && token){
					return localStorage.setItem("auth_token", token)
				}else if (k === "auth_user" && user){
					return localStorage.setItem(AUTH_USER, JSON.stringify(user))
				}else {
					console.log("Clear from storage ", k)
					return localStorage.removeItem(k)
				}
			})
		}catch (e){
			console.log("Error while clearing localstorage: ", e)
		}

		sessionStorage.clear();
	}

	/**
	 * Set user profile to localstorage
	 */
	static setUserProfileToLocalstorage(userProfileObj) {
		try {
			localStorage.setItem(AUTH_USER, JSON.stringify(userProfileObj));
		} catch(error) {
			console.log("setUserProfileToLocalstorage: Error", error)
		}
	}

	/**
	 * Get user profile from localstorage
	 */
	static getUserProfileFromLocalstorage() {
		try {
			return JSON.parse(localStorage.getItem(AUTH_USER))
		} catch(error) {
			return {uid:'', roles: []}
		}
	}

	static getUserProfileFromStore(){
		try {
			const obj = {};
			if (obj?.uid) {
				return obj ? obj : {uid: '', roles: []}
			} else {
				// console.warn("getUserProfileFromStore: no user in store, get from local storage")
				const ls_obj = this.getUserProfileFromLocalstorage()
				if (ls_obj?.uid) {
					this.setUserProfileToStore(ls_obj)
					return ls_obj
				} else {
					return {uid: '', roles: []}
				}
			}
		} catch(error) {
			return this.getUserProfileFromLocalstorage()
		}
	}

	static destroyUserCookies() {
		document.cookie = 'filter=; expires=Thu, 01 Jan 1970 00:00:00 GMT;'
	}

	static setUserProfileToStore(){
		// const dispatch = DispatchFunc()

		try {
			// dispatch(user(data))
			// dispatch(setUser(user));
		} catch(error) {
			return {}
		}
	}
	/**
	 * Get user settled app mode from localstorage
	 */
	static getAuthUserProfile(){
		return this.getUserProfileFromStore()
	}

	/**
	 * Get user settled app language from localstorage
	 */
	static getUserSettledLangMode() {
		return localStorage.getItem("app-lang")
	}

	static getElapsedTime(dateA, dateB) {
		const range = this.moment.range(dateA, dateB ? dateB : new Date())
		if (range.diff('year', false) > 0) return range.diff('year', false) + " г"
		if (range.diff('month', false) > 0) return range.diff('month', false) + " м"
		if (range.diff('day', false) > 0) return range.diff('day', false) + " д"
		if (range.diff('hour', false) > 0) return range.diff('hour', false) + " ч"
		if (range.diff('minute', false) > 0) return range.diff('minute', false) + " мин"
		if (range.diff('second', false) > 0) return range.diff('second', false) + " сек"
		if (range.diff('millisecond', false) > 0) return range.diff('millisecond', true) + " мс"
	}

	static getCurrentPath() {
		if (typeof window !== "undefined") {
			return window.location.pathname
		} else {
			return ""
		}
	}

	static getCurrentDomainAndProtocol() {
		if (typeof window !== "undefined") {
			try {
				return window.location.href.split('/').slice(0, 3).join('/')
			} catch(e) {
				return ""
			}
		} else {
			return ""
		}
	};


	static isLocalhost() {
		if (typeof window !== "undefined") {
			try {
				return window.location.hostname === "localhost";
			} catch(e) {
				return false
			}
		} else {
			return false
		}
	}

	static getIsDevelopmentDomain() {
		if (typeof window !== "undefined") {
			try {
				return window.location.href.split('/').slice(0, 3).join('/') === "https://stage.proxy-crm.com" ||
					window.location.href.split('/').slice(0, 3).join('/') === "http://localhost:8077";
			} catch(e) {
				return ""
			}
		} else {
			return ""
		}
	}

	/**
	 * Download file
	 * @param {Object||Blob} file - file for download
	 * @param {String} name - name file (by default name if 'untitled')
	 * @return {undefined}
	 */
	static fileDownload(file, name = "untitled") {
		if (typeof window !== "undefined") {
			try {
				const blobFile = file instanceof Blob? file : new Blob([file])
				const url = window.URL.createObjectURL(blobFile);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', name);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				URL.revokeObjectURL(url);
			} catch(e) {
				console.error("Download file: some error!", e)
			}
		} else {
			console.error("Download file: windows is undefined!")
		}
	}

	/**
	 * Validation input only numbers allows
	 * @param event
	 * @param type
	 */
	static blockInvalidChar (event, type) {
		const ctrl = event.ctrlKey ? event.ctrlKey : (event.key === 17)
		const macCmd = event.keyCode === 91 || event.keyCode === 93 || event.key === 'Meta' || event.metaKey;
		const Backspace = event.key === "Backspace" || event.keyCode === 37 || event.keyCode === 39;

		const rgxText = /[a-zA-ZА-Яа-яЁё]+/;
		const rgxNumber = /^[0-9]*\.?[0-9]*$/;
		const rgxCommaNumber = /^[0-9,./]*?[0-9,]*$/;
		const rgxNoCommaNumber = /^[0-9.]*\.?[0-9.]*$/;
		const login = /[а-іїяАЇ-ЯёЁ,\s]/g;

		switch (type){
			case "space":
				return event.keyCode === 32 && event.preventDefault();
			case "ip":
				return !event.key.match(rgxCommaNumber) && !Backspace && !macCmd && !ctrl && event.preventDefault();
			case "number":
				return !event.key.match(rgxNoCommaNumber) && !Backspace && !macCmd && !ctrl && event.preventDefault();
			case "port":
				return !event.key.match(rgxCommaNumber) && !Backspace && !macCmd && !ctrl && event.preventDefault();
			case "text":
				return !event.key.match(rgxText) && !Backspace && !macCmd && !ctrl && event.preventDefault();
			case "login":
				return event.key.match(login) && !Backspace && !macCmd && !ctrl && event.preventDefault();
			default:
				return !event.key.match(rgxNumber) && !Backspace && !macCmd && !ctrl && event.preventDefault();
		}

		// return ['e', 'E', '+', '-', '.'].includes(event.key) && event.preventDefault();

	}

	/**
	 * Reset unnecessary previous request
	 * @param api object
	 * @return signal from AbortController
	 */

	static createCancelTokenHandler(apiObject) {
		const CancelToken = axios.CancelToken;

		const cancelTokenHandler = {};

		Object
			.getOwnPropertyNames(apiObject)
			.forEach(propertyName => {
				const cancelTokenRequestHandler = {
					cancelToken: undefined
				}

				cancelTokenHandler[propertyName] = {
					handleRequestCancellation: () => {
						cancelTokenRequestHandler.cancelToken && cancelTokenRequestHandler.cancelToken.cancel(`${propertyName} canceled`)
						cancelTokenRequestHandler.cancelToken = CancelToken.source();

						return cancelTokenRequestHandler.cancelToken;
					}
				}
			})

		return cancelTokenHandler;
	}

	/**
	 * String to span
	 * @param {String} string - string for cut
	 * @param {String} className - class names
	 * @param {Number} length - max string length
	 * @param noTooltip - boolean
	 * @return {Object}
	 */
	static spanCuterRight(string, className, length = 25, noTooltip) {
		try {
			if (string.length > (length - 3)) {
				if(noTooltip){
					return string.substring(0, length - 3) + "..."
				}else{
					return (
						<Tooltip placement="top" title={string.substring(0, 50) + "..."}>
							<p style={{marginBottom: 0, color: "inherit"}} className={className}>{string.substring(0, length - 3) + "..."}</p>
						</Tooltip>
					)
				}

			} else {
				return string
			}
		} catch(e) {
			return console.error("Util: spanCuter, string: "+string)
		}
	}

	/**
	 * Validation ip on front
	 * @param {String} string - string for validation
	 * @return {Boolean}
	 */

	static getValidationIp (ip) {
		if(ip){
			const regEx = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
			return ip.match(regEx)
		}else{
			return ip
		}
	}

	static getValidationIpWithMask (ip) {
		if (ip) {
			// eslint-disable-next-line
			const regEx = '^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/(3[0-2]|[0-2][0-9]|[0-9]|[1-9]?\d)$'
			return ip.match(regEx)
		} else {
			return ip
		}
	}

	static setCookieByName(name, value, props) {
		if(document.cookie.includes('filter')){
			return document.cookie = `${name}=${value}; path=${props.match.url} filter=${document.cookie.slice(document.cookie.indexOf("=")+1)}; path=/app/ticket`
		}else {
			return document.cookie = `${name}=${value}; path=${props.match.url}`
		}
	}

	/**
	 * Get clear cookie by name
	 * @param {Object} route props
	 * @param {String} string - name of cookie
	 */
	static getClearCookieByName(name, location) {
		if(name === 'filter'){
			document.cookie = `${name}=; path=/app/ticket; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
			document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
		}else{
			document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
			document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
			document.cookie = `${name}=; path=${location.pathname}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
		}

	}

	static getClearPageData(location) {
		const ids = location?.pathname.split("/").filter(el => /\d/.test(el))
		try {
			if(!location.pathname.includes('webHost')){
				localStorage.removeItem("tab")
			}
			if(!location.pathname.includes('clients')){
				localStorage.removeItem("table")
			}
			if(!location.pathname.includes('docker')){
				localStorage.removeItem("docker")
				if(ids.length>0){
					apiService.getDockerDisconnect(ids[ids.length-1]).then()
				}
			}
		}catch (e){
			console.log(`Clearing on page data error ${location?.pathname}: `, e)
		}

	}

	static getCookieByName = (name) => {
		let dataStr = null;

		document.cookie.split(';').forEach(el => {
			if(el.slice(0, el.indexOf("="))===name){
				dataStr = el.slice(el.indexOf("=")+1)
			}
		})
		return dataStr
	}

	static formatSpeedInBits(bytesPerSecond, precision = 1) {
		const bitsPerSecond = bytesPerSecond * 8;
		const units = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps'];

		const pow = Math.floor(bitsPerSecond ? Math.log(bitsPerSecond) / Math.log(1000) : 0);
		const powLimited = Math.min(pow, units.length - 1);

		const result = bitsPerSecond / Math.pow(1000, powLimited);

		return result.toFixed(precision) + ' ' + units[powLimited];
	}

	static convertBitsToMegabits(bites) {
		if(!bites){
			return '0 bits'
		}

		if(typeof bites === 'number') {
			const units = ['bits', 'Kbps', 'Mbps', 'Gbps']
			if(bites === 0 || !bites){
				return `${bites} bits`
			}

			let i = 0
			for (i; bites > 1000; i++) {
				bites /= 1000;
			}
			return parseFloat(bites.toFixed(2)) + ' ' + units[i]
		}
	}

	static getFormatBytes = (bytes, decimals=2) => {
		if(bytes === 0){
			return `0 KB`
		}

		if(bytes && typeof bytes === 'number') {
			let i = 0

			for (i; bytes > 1024; i++) {
				bytes /= 1024;
			}
			return parseFloat(bytes.toFixed(decimals)) + ' ' + dataTrafficUnits[i]
		}
	}

	static calculateSize = (bytes, size) => {
		switch (size){
			case 'PB':
				return ((bytes) / 1024 /1024 / 1024 / 1024 / 1024).toFixed(2) + " PB";
			case 'TB':
				return ((bytes) / 1024 /1024 / 1024 / 1024).toFixed(2) + " TB";
			case 'GB':
				return (bytes / 1073741824).toFixed(2) + " GB";
			case 'MB':
				return (bytes / 1048576).toFixed(2) + " MB";
			case 'KB':
				return (bytes / 1024).toFixed(2) + " KB"
			default:
				return (bytes / 1048576).toFixed(2) + " MB";
		}
	}

	static getPercentage(partialValue, totalValue) {
		return Math.round((100 * partialValue) / totalValue);
	}

	static insertWordToText = (text, word, from, to) => {
		const arr = Array.from(text);
		arr.splice(from, to,  word)
		return arr.join('')
	}
}


export default Utils;