import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {USER_THEME_CONFIG} from 'configs/AppConfig'
import apiService from "../../services/ApiService";
import {AUTH_TOKEN} from "../../constants/AuthConstant";
import {message} from "antd";

export const initialState = USER_THEME_CONFIG

export const getUser = createAsyncThunk('user/login',async () => {
	// const tryAuth = new Date().getTime();
	try {
		const response = await apiService.usersGetAuthUser();
		if (response) {
			localStorage.setItem(AUTH_TOKEN, response?.uid);
			return response
		} else {
			console.log("Oops, something wrong")
			message.error("Oops, something wrong")
			return initialState
		}
	} catch (err) {
		console.log("Oops, we have some error")
		message.error("Oops, we have some error")
		return initialState
	}
})

export const userConfigSlice = createSlice({
	name: 'theme',
	initialState,
	reducers: {
		onSetUser: (state, action) => {
			state.user = action.payload
		},
        toggleCollapsedNav: (state, action) => {
			state.navCollapsed = action.payload
		},
        onNavStyleChange: (state, action) => {
			state.sideNavTheme = action.payload
		},
        onLocaleChange: (state, action) => {
			state.locale = action.payload
		},
        onNavTypeChange: (state, action) => {
			state.navType = action.payload
		},
        onTopNavColorChange: (state, action) => {
			state.topNavColor = action.payload
		},
        onHeaderNavColorChange: (state, action) => {
			state.headerNavColor = action.payload
		},
        onMobileNavToggle: (state, action) => {
			state.mobileNav = action.payload
		},
        onSwitchTheme: (state, action) => {
			state.currentTheme = action.payload
			state.loading = false
		},
        onDirectionChange: (state, action) => {
			state.direction = action.payload
		},
		onBlankLayout: (state, action) => {
			state.blankLayout = action.payload
		},
		onLoadingChange: (state, action) => {
			state.loading = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUser.pending, (state, action) => {
				state.loading = true
			})
			.addCase(getUser.fulfilled, (state, action) => {
				state.user = action.payload
				state.loading = false
				state.currentTheme = action.payload?.uiThemeColor
				state.locale = action.payload?.uiLanguage
			})
			.addCase(getUser.rejected, (state, action) => {
				state.user = action.payload
			})
	}
})

export const { 
    toggleCollapsedNav,
    onNavStyleChange,
    onLocaleChange,
    onNavTypeChange,
    onTopNavColorChange,
    onHeaderNavColorChange,
    onMobileNavToggle,
    onSwitchTheme,
    onDirectionChange,
	onLoadingChange,
	onBlankLayout
} = userConfigSlice.actions

export default userConfigSlice.reducer