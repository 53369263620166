import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import { BrowserRouter  } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import {NotificationProvider} from "utils/contexts/NotificationContext";
import store from './store';
import history from './history'
import Layouts from './layouts'
import { USER_THEME_CONFIG } from './configs/AppConfig';
import './lang'
import apiService from "./services/ApiService";
import Utils from "./utils";
import {StompSessionProvider} from "react-stomp-hooks";
import {IntlProvider} from "react-intl";
import {catchErrorLocale} from "./services/SetLocaleServise";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const genString = (length) => {
    return Array(length).fill("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz").map(function (x) {
      return x[Math.floor(Math.random() * x.length)]
    }).join('');
  }

  useEffect(() => {
    // check app MD5
    try {
      apiService.getAppMD5().then((req) => {
        try {
          if (req?.status === 200) {
            const currentMd5 = req.data
            const localMd5 = localStorage.getItem("app_md5")
            if (localMd5 !== currentMd5) {
              Utils.clearSessionAndLocalStorage().then(() => {
                Utils.clearBrowserCache();
                Utils.clearServiceWorkerCache();
                localStorage.setItem("app_build", process.env.REACT_APP_GH_BUILD);
                localStorage.setItem("app_md5", currentMd5);
              });
            }
          }
        } catch (e) {
          console.error("[Promise] App MD5 check error", e)
        }
      })
    } catch (e) {
      console.error("App MD5 check error", e)
    }

    if (!sessionStorage.reactJSId) {
      sessionStorage.reactJSId = `${genString(15)}${new Date().getTime()}${genString(2)}`;
    }
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <NotificationProvider>
            <IntlProvider
                locale={"ru"}
                // messages={currentAppLocale?.messages}
                onError={catchErrorLocale}
            >
              <StompSessionProvider url={`${Utils.getCurrentDomainAndProtocol()}/api/websocket/inner`}>
                <ThemeSwitcherProvider
                    themeMap={themes}
                    defaultTheme={USER_THEME_CONFIG.currentTheme}
                    insertionPoint="styles-insertion-point"
                >
                  <Layouts />
                </ThemeSwitcherProvider>
              </StompSessionProvider>
            </IntlProvider>
          </NotificationProvider>
        </BrowserRouter>  
      </Provider>
    </div>
  );
}

export default App;
