import React from 'react'

import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
        {
            key: 'login',
            path: `${AUTH_PREFIX_PATH}/login`,
            component: React.lazy(() => import('views/auth-views/authentication/login')),
        },
        {
            key: 'login-1',
            path: `${AUTH_PREFIX_PATH}/login-1`,
            component: React.lazy(() => import('views/auth-views/authentication/login-1')),
        },
        {
            key: 'login-2',
            path: `${AUTH_PREFIX_PATH}/login-2`,
            component: React.lazy(() => import('views/auth-views/authentication/login-2')),
        },
        {
            key: 'register-1',
            path: `${AUTH_PREFIX_PATH}/register-1`,
            component: React.lazy(() => import('views/auth-views/authentication/register-1')),
        },
        {
            key: 'register-2',
            path: `${AUTH_PREFIX_PATH}/register-2`,
            component: React.lazy(() => import('views/auth-views/authentication/register-2')),
        },
        {
            key: 'forgot-password',
            path: `${AUTH_PREFIX_PATH}/forgot-password`,
            component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
        },
        {
            key: 'error-page-1',
            path: `${AUTH_PREFIX_PATH}/error-page-1`,
            component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
        },
        {
            key: 'error-page-2',
            path: `${AUTH_PREFIX_PATH}/error-page-2`,
            component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
        },
    ]

export const ProtectedRoutes = [
    // CRM routes
    // Orders
    {
        key: 'proxy.order-list',
        path: `${APP_PREFIX_PATH}/proxy/order/list`,
        component: React.lazy(() => import('views/app-views/proxy/orders/list')),
    },
    {
        key: 'proxy.order.create',
        path: `${APP_PREFIX_PATH}/proxy/order/create`,
        component: React.lazy(() => import('views/app-views/proxy/orders/create')),
    },
    {
        key: 'proxy.order.edit',
        path: `${APP_PREFIX_PATH}/proxy/order/:id/edit`,
        component: React.lazy(() => import('views/app-views/proxy/orders/edit')),
    },
    {
        key: 'proxy.order.historyDetails',
        path: `${APP_PREFIX_PATH}/proxy/order/history/details/:id`,
        component: React.lazy(() => import('views/app-views/proxy/orders/historyDetails')),
    },
    {
        key: 'proxy.order.history',
        path: `${APP_PREFIX_PATH}/proxy/order/history/:historyId`,
        component: React.lazy(() => import('views/app-views/proxy/orders/history')),
    },
    // Clients
    {
        key: 'clients.list',
        path: `${APP_PREFIX_PATH}/clients/list`,
        component: React.lazy(() => import('views/app-views/proxy/clients/list')),
    },
    {
        key: 'clients.create',
        path: `${APP_PREFIX_PATH}/clients/create`,
        component: React.lazy(() => import('views/app-views/proxy/clients/create')),
    },
    {
        key: 'clients.edit',
        path: `${APP_PREFIX_PATH}/clients/:id/edit`,
        component: React.lazy(() => import('views/app-views/proxy/clients/edit')),
    },
    {
        key: 'clients.analysis',
        path: `${APP_PREFIX_PATH}/clients/:id/analysis/:analysisId`,
        component: React.lazy(() => import('views/app-views/proxy/clients/analysis')),
    },
    {
        key: 'clients.history',
        path: `${APP_PREFIX_PATH}/clients/:id/activity`,
        component: React.lazy(() => import('views/app-views/proxy/clients/activity')),
    },
    {
        key: 'clients.search',
        path: `${APP_PREFIX_PATH}/clients/auth/search`,
        component: React.lazy(() => import('views/app-views/proxy/clients/search')),
    },
    {
        key: 'clients.fingerprint',
        path: `${APP_PREFIX_PATH}/clients/:id/fingerprint`,
        component: React.lazy(() => import('views/app-views/proxy/clients/fingerprint')),
    },
    {
        key: 'clients.traffic',
        path: `${APP_PREFIX_PATH}/clients/:id/traffic`,
        component: React.lazy(() => import('views/app-views/proxy/clients/traffic')),
    },
    {
        key: 'clients.traffic.total',
        path: `${APP_PREFIX_PATH}/clients/traffic`,
        component: React.lazy(() => import('views/app-views/proxy/clients/totalDailyTraffic')),
    },
    {
        key: 'clients.ftpk',
        path: `${APP_PREFIX_PATH}/clients/:id/fptk`,
        component: React.lazy(() => import('views/app-views/proxy/clients/fptk')),
    },
    {
        key: 'clients.auth',
        path: `${APP_PREFIX_PATH}/clients/:id/auth/:authId`,
        component: React.lazy(() => import('views/app-views/proxy/clients/auth')),
    },
    {
        key: 'clients.auth-history.id',
        path: `${APP_PREFIX_PATH}/clients/:id/auth-history/:authId`,
        component: React.lazy(() => import('views/app-views/proxy/clients/history-auth')),
    },
    {
        key: 'clients.auth-history',
        path: `${APP_PREFIX_PATH}/clients/:id/auth-history`,
        component: React.lazy(() => import('views/app-views/proxy/clients/history-auth')),
    },
    {
        key: 'clients.bitrix',
        path: `${APP_PREFIX_PATH}/clients/:id/bitrix`,
        component: React.lazy(() => import('views/app-views/proxy/clients/bitrix24')),
    },
    {
        key: 'clients.pipedrive',
        path: `${APP_PREFIX_PATH}/clients/:id/pipedrive`,
        component: React.lazy(() => import('views/app-views/proxy/clients/pipedrive')),
    },
    // Clients change email history
    {
        key: 'clients.change-email-history',
        path: `${APP_PREFIX_PATH}/clients/email-change-history/list`,
        component: React.lazy(() => import('views/app-views/proxy/client-change-email-history/list')),
    },
    // Fingerprints all
    {
        key: 'fingerprints.all',
        path: `${APP_PREFIX_PATH}/fingerprint/list`,
        component: React.lazy(() => import('views/app-views/proxy/fingerprint')),
    },
    // Massive replacement IP
    {
        key: 'hostProviderReplacement.list',
        path: `${APP_PREFIX_PATH}/hostProviderReplacement/list`,
        component: React.lazy(() => import('views/app-views/proxy/hostProviderReplacement/list')),
    },
    {
        key: 'hostProviderReplacement.open',
        path: `${APP_PREFIX_PATH}/hostProviderReplacement/:id/open`,
        component: React.lazy(() => import('views/app-views/proxy/hostProviderReplacement/open')),
    },
    {
        key: 'hostProviderReplacement.replace',
        path: `${APP_PREFIX_PATH}/hostProviderReplacement/replace`,
        component: React.lazy(() => import('views/app-views/proxy/hostProviderReplacement/replace')),
    },
    // Replacement IP
    {
        key: 'replacementIp.open',
        path: `${APP_PREFIX_PATH}/replacementIp/:id/open`,
        component: React.lazy(() => import('views/app-views/proxy/replacementIp/open')),
    },
    // Request replacement
    {
        key: 'requestReplacement.list',
        path: `${APP_PREFIX_PATH}/requestReplacement/list`,
        component: React.lazy(() => import('views/app-views/proxy/requestReplacements/list')),
    },
    {
        key: 'requestReplacement.templates',
        path: `${APP_PREFIX_PATH}/requestReplacement/templates`,
        component: React.lazy(() => import('views/app-views/proxy/requestReplacements/templates')),
    },
    // Tickets
    {
        key: 'ticket.list',
        path: `${APP_PREFIX_PATH}/ticket/list`,
        component: React.lazy(() => import('views/app-views/proxy/ticket/list')),
    },
    {
        key: 'ticket.create',
        path: `${APP_PREFIX_PATH}/ticket/create`,
        component: React.lazy(() => import('views/app-views/proxy/ticket/create')),
    },
    {
        key: 'ticket.edit',
        path: `${APP_PREFIX_PATH}/ticket/:id`,
        component: React.lazy(() => import('views/app-views/proxy/ticket/edit')),
    },
    // Proxy projects
    {
        key: 'projects.list',
        path: `${APP_PREFIX_PATH}/proxy/projects/list`,
        component: React.lazy(() => import('views/app-views/proxy/proxyProjects/list')),
    },
    {
        key: 'projects.create',
        path: `${APP_PREFIX_PATH}/proxy/projects/create`,
        component: React.lazy(() => import('views/app-views/proxy/proxyProjects/create')),
    },
    {
        key: 'projects.edit',
        path: `${APP_PREFIX_PATH}/proxy/projects/:id/edit`,
        component: React.lazy(() => import('views/app-views/proxy/proxyProjects/edit')),
    },
    // Finance reports
    {
        key: 'reports.main',
        path: `${APP_PREFIX_PATH}/reports/main`,
        component: React.lazy(() => import('views/app-views/finance/reports/main')),
    },
    // Finance refunds
    {
        key: 'refunds.list',
        path: `${APP_PREFIX_PATH}/refunds/list`,
        component: React.lazy(() => import('views/app-views/finance/refunds/list')),
    },
    {
        key: 'refunds.edit',
        path: `${APP_PREFIX_PATH}/refunds/:id/edit`,
        component: React.lazy(() => import('views/app-views/finance/refunds/edit')),
    },
    // Finance withdrawals
    {
        key: 'affiliateProgram.list',
        path: `${APP_PREFIX_PATH}/affiliateProgram/list`,
        component: React.lazy(() => import('views/app-views/finance/affiliateProgram/list')),
    },
    {
        key: 'affiliateProgram.edit',
        path: `${APP_PREFIX_PATH}/affiliateProgram/:id/edit`,
        component: React.lazy(() => import('views/app-views/finance/affiliateProgram/edit')),
    },
    // Management band limited
    {
        key: 'bandlim.list',
        path: `${APP_PREFIX_PATH}/bandlim/list`,
        component: React.lazy(() => import('views/app-views/management/bandLimited/list')),
    },
    {
        key: 'bandlim.edit',
        path: `${APP_PREFIX_PATH}/bandlim/:id/edit`,
        component: React.lazy(() => import('views/app-views/management/bandLimited/list')),
    },
    {
        key: 'bandlim.auth',
        path: `${APP_PREFIX_PATH}/bandlim/auth/:authId/edit`,
        component: React.lazy(() => import('views/app-views/management/bandLimited/list')),
    },
    // Management console proxy
    {
        key: 'console.proxy',
        path: `${APP_PREFIX_PATH}/console/proxy`,
        component: React.lazy(() => import('views/app-views/management/console/proxy')),
    },
    // Management mobile operators
    {
        key: 'mobileOperators.list',
        path: `${APP_PREFIX_PATH}/mobileOperators/list`,
        component: React.lazy(() => import('views/app-views/management/mobileOperators/list')),
    },
    {
        key: 'mobileOperators.edit',
        path: `${APP_PREFIX_PATH}/mobileOperators/:id/edit`,
        component: React.lazy(() => import('views/app-views/management/mobileOperators/edit')),
    },
    {
        key: 'mobileOperators.create',
        path: `${APP_PREFIX_PATH}/mobileOperators/create`,
        component: React.lazy(() => import('views/app-views/management/mobileOperators/create')),
    },
    // Management mobile aggregators
    {
        key: 'aggregators.list',
        path: `${APP_PREFIX_PATH}/aggregators/list`,
        component: React.lazy(() => import('views/app-views/management/mobileAggregators/list')),
    },
    {
        key: 'aggregators.edit',
        path: `${APP_PREFIX_PATH}/aggregators/:id/edit`,
        component: React.lazy(() => import('views/app-views/management/mobileAggregators/edit')),
    },
    // Management modems
    {
        key: 'modems.list',
        path: `${APP_PREFIX_PATH}/modems/list`,
        component: React.lazy(() => import('views/app-views/management/modems/list')),
    },
    // Management hostProviders
    {
        key: 'hosts.list',
        path: `${APP_PREFIX_PATH}/proxy/hosts/list`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/list')),
    },
    {
        key: 'hosts.create',
        path: `${APP_PREFIX_PATH}/proxy/hosts/create`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/create')),
    },
    {
        key: 'hosts.docker',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/edit/docker`,
        component: React.lazy(() => import(`components/shared-components/crm/docker/main`)),
    },
    {
        key: 'hosts.edit',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/edit`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/edit')),
    },
    {
        key: 'hosts.merge',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/merge`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/hostMerge')),
    },
    {
        key: 'hosts.edit-new',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/edit-new`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/new-edit')),
    },
    {
        key: 'hosts.edit-ip',
        path: `${APP_PREFIX_PATH}/proxy/hosts/ip/:id/edit`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/edit/ipEdit')),
    },
    {
        key: 'hosts.cfg',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/cfg`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/cfg')),
    },
    {
        key: 'hosts.ssh',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/ssh`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/ssh')),
    },
    {
        key: 'hosts.ipv6PortFrag',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/ipv6PortFrag`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/IPv6FragmentationPortNew')),
    },
    {
        key: 'hosts.phantom-hosts',
        path: `${APP_PREFIX_PATH}/proxy/hosts/phantom-hosts`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/phantomHosts')),
    },
    {
        key: 'hosts.terminal',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/terminal`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/terminal')),
    },
    {
        key: 'hosts.statuses',
        path: `${APP_PREFIX_PATH}/proxy/hosts/statuses`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/statuses')),
    },
    {
        key: 'hosts.transactions',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/transactions`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/transactions')),
    },
    {
        key: 'hosts.envs',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/env`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/env')),
    },
    {
        key: 'hosts.logs',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/logs/:tag`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/logs')),
    },
    {
        key: 'hosts.updates',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/hostUpdates`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/hostUpdates')),
    },
    {
        key: 'hosts.history',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/history`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/history')),
    },
    {
        key: 'hosts.history.details',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/history/:historyId/details`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/historyDetails')),
    },
    {
        key: 'hosts.proxySdk.clientDetails',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/proxy-sdk/:containerId/details/:authId`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/proxy-sdk/details')),
    },
    {
        key: 'hosts.proxySdk',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/proxy-sdk/:containerId`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/proxy-sdk/main')),
    },
    {
        key: 'hosts.proxySdk.diagnosis',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/proxy-sdk/:containerId/diagnosis`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/proxy-sdk/diagnostics')),
    },
    {
        key: 'hosts.proxySdk.fileDownload',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/proxy-sdk/:containerId/files`,
        component: React.lazy(() => import('views/app-views/management/hostProvider/proxy-sdk/download')),
    },
    {
        key: 'hosts.docker',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/edit/docker/main`,
        component: React.lazy(() => import('components/shared-components/crm/docker/main')),
    },
    {
        key: 'hosts.docker.container',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/edit/docker/:containerId/container`,
        component: React.lazy(() => import('components/shared-components/crm/docker/container')),
    },
    {
        key: 'hosts.docker.container.stats',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/edit/docker/:containerId/container/stats`,
        component: React.lazy(() => import('components/shared-components/crm/docker/statistic')),
    },
    {
        key: 'hosts.docker.container.stats',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/edit/docker/:containerId/container/logs`,
        component: React.lazy(() => import('components/shared-components/crm/docker/logs')),
    },
    {
        key: 'hosts.docker.container.new',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/edit/docker/new-container`,
        component: React.lazy(() => import('components/shared-components/crm/docker/addContainer')),
    },
    {
        key: 'hosts.docker.container.image',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/edit/docker/:imageId/image`,
        component: React.lazy(() => import('components/shared-components/crm/docker/image')),
    },
    {
        key: 'hosts.docker.container.network',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/edit/docker/:networkId/network`,
        component: React.lazy(() => import('components/shared-components/crm/docker/network')),
    },
    {
        key: 'hosts.docker.container.overview',
        path: `${APP_PREFIX_PATH}/proxy/hosts/:id/edit/docker/overview`,
        component: React.lazy(() => import('components/shared-components/crm/docker/overview')),
    },
    // Management providers
    {
        key: 'serverProviders.list',
        path: `${APP_PREFIX_PATH}/serverProviders/list`,
        component: React.lazy(() => import('views/app-views/management/providers/list')),
    },
    {
        key: 'serverProviders.edit',
        path: `${APP_PREFIX_PATH}/serverProviders/:id/edit`,
        component: React.lazy(() => import('views/app-views/management/providers/edit')),
    },
    {
        key: 'serverProviders.create',
        path: `${APP_PREFIX_PATH}/serverProviders/create`,
        component: React.lazy(() => import('views/app-views/management/providers/create')),
    },
    {
        key: 'serverProviders.account',
        path: `${APP_PREFIX_PATH}/serverProviders/:providerId/hosts/:accountId`,
        component: React.lazy(() => import(`views/app-views/management/providers/hosts`)),
    },
    {
        key: 'serverProviders.account',
        path: `${APP_PREFIX_PATH}/serverProviders/:accountId/account-hosts`,
        component: React.lazy(() => import(`views/app-views/management/providers/hosts`)),
    },
    {
        key: 'serverProviders.account',
        path: `${APP_PREFIX_PATH}/serverProviders/:providerId/hosts`,
        component: React.lazy(() => import(`views/app-views/management/providers/hosts`)),
    },
    // Management clusters
    {
        key: 'clusters.list',
        path: `${APP_PREFIX_PATH}/web/clusters/list`,
        component: React.lazy(() => import('views/app-views/management/clusters/list')),
    },
    {
        key: 'clusters.edit',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit`,
        component: React.lazy(() => import('views/app-views/management/clusters/edit')),
    },
    {
        key: 'cluster.webHost.docker',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/:webHostId/webHost/docker`,
        component: React.lazy(() => import('components/shared-components/crm/docker/main')),
    },
    {
        key: 'cluster.webHost.historyDetails',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/:webHostId/webHost/:historyId/history`,
        component: React.lazy(() => import('views/app-views/management/clusters/webHost/historyDetails')),
    },
    {
        key: 'cluster.webHost.create',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/new-webHost`,
        component: React.lazy(() => import('views/app-views/management/clusters/webHost/create')),
    },
    {
        key: 'cluster.webHost',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/:webHostId/webHost`,
        component: React.lazy(() => import('views/app-views/management/clusters/webHost/edit')),
    },
    {
        key: 'cluster.newCluster',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit-new`,
        component: React.lazy(() => import('views/app-views/management/clusters/edit-old')),
    },
    {
        key: 'cluster.docker.container',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/:webHostId/webHost/docker/:containerId/container`,
        component: React.lazy(() => import('components/shared-components/crm/docker/container')),
    },
    {
        key: 'cluster.docker.container',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/:webHostId/webHost/docker/main`,
        component: React.lazy(() => import('components/shared-components/crm/docker/main')),
    },
    {
        key: 'cluster.docker.container',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/:webHostId/webHost/docker/:containerId/container`,
        component: React.lazy(() => import('components/shared-components/crm/docker/container')),
    },
    {
        key: 'cluster.docker.container.stats',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/:webHostId/webHost/docker/:containerId/container/stats`,
        component: React.lazy(() => import('components/shared-components/crm/docker/statistic')),
    },
    {
        key: 'cluster.docker.container.stats',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/:webHostId/webHost/docker/:containerId/container/logs`,
        component: React.lazy(() => import('components/shared-components/crm/docker/logs')),
    },
    {
        key: 'cluster.docker.container.new',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/:webHostId/webHost/docker/new-container`,
        component: React.lazy(() => import('components/shared-components/crm/docker/addContainer')),
    },
    {
        key: 'cluster.docker.container.image',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/:webHostId/webHost/docker/:imageId/image`,
        component: React.lazy(() => import('components/shared-components/crm/docker/image')),
    },
    {
        key: 'cluster.docker.container.network',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/:webHostId/webHost/docker/:networkId/network`,
        component: React.lazy(() => import('components/shared-components/crm/docker/network')),
    },
    {
        key: 'cluster.docker.container.overview',
        path: `${APP_PREFIX_PATH}/web/clusters/:id/edit/:webHostId/webHost/docker/overview`,
        component: React.lazy(() => import('components/shared-components/crm/docker/overview')),
    },
    // Management clusters - Operating systems
    {
        key: 'operationSystems.list',
        path: `${APP_PREFIX_PATH}/web/operationSystems/list`,
        component: React.lazy(() => import(`views/app-views/management/operationSystems/list`)),
    },
    {
        key: 'operationSystems.edit',
        path: `${APP_PREFIX_PATH}/web/operationSystems/:id/edit`,
        component: React.lazy(() => import(`views/app-views/management/operationSystems/edit`)),
    },
    {
        key: 'serverProviders.create',
        path: `${APP_PREFIX_PATH}/web/operationSystems/create`,
        component: React.lazy(() => import(`views/app-views/management/operationSystems/edit`)),
    },
    // Management clusters - Operating operation scripts
    {
        key: 'operationSystemScripts.list',
        path: `${APP_PREFIX_PATH}/web/operationSystemScripts/list`,
        component: React.lazy(() => import(`views/app-views/management/operationSystemScripts/list`)),
    },
    {
        key: 'operationSystemScripts.edit',
        path: `${APP_PREFIX_PATH}/web/operationSystemScripts/:id/edit`,
        component: React.lazy(() => import(`views/app-views/management/operationSystemScripts/edit`)),
    },
    {
        key: 'operationSystemScripts.create',
        path: `${APP_PREFIX_PATH}/web/operationSystemScripts/create`,
        component: React.lazy(() => import(`views/app-views/management/operationSystemScripts/create`)),
    },
    // Management proxy IPs
    {
        key: 'proxyIP.list',
        path: `${APP_PREFIX_PATH}/proxy/ip/list`,
        component: React.lazy(() => import(`views/app-views/management/proxyIp/list`)),
    },
    // Management modules
    {
        key: 'modules.list',
        path: `${APP_PREFIX_PATH}/updates/modules/list`,
        component: React.lazy(() => import(`views/app-views/management/modules/list`)),
    },
    {
        key: 'modules.edit',
        path: `${APP_PREFIX_PATH}/updates/modules/:id/edit`,
        component: React.lazy(() => import(`views/app-views/management/modules/edit`)),
    },
    {
        key: 'modules.create',
        path: `${APP_PREFIX_PATH}/updates/modules/:id/edit/hostsUpdate`,
        component: React.lazy(() => import(`views/app-views/management/modules/edit/create`)),
    },
    {
        key: 'modules.open',
        path: `${APP_PREFIX_PATH}/updates/modules/:id/open/:hostId`,
        component: React.lazy(() => import(`views/app-views/management/modules/edit/open`)),
    },
    // Management Software packages
    {
        key: 'softwarePackage.list',
        path: `${APP_PREFIX_PATH}/updates/softwarePackage/list`,
        component: React.lazy(() => import(`views/app-views/management/softwarePackage/list`)),
    },
    {
        key: 'softwarePackage.open',
        path: `${APP_PREFIX_PATH}/updates/softwarePackage/:id/open`,
        component: React.lazy(() => import(`views/app-views/management/softwarePackage/open`)),
    },
    {
        key: 'softwarePackage.list',
        path: `${APP_PREFIX_PATH}/updates/softwarePackage/:id/edit`,
        component: React.lazy(() => import(`views/app-views/management/softwarePackage/edit`)),
    },
    {
        key: 'softwareUpdates.list',
        path: `${APP_PREFIX_PATH}/updates/softwareUpdates/list`,
        component: React.lazy(() => import(`views/app-views/management/softwareUpdates/list`)),
    },
    // Sites order sources
    {
        key: 'orderSource.list',
        path: `${APP_PREFIX_PATH}/orderSource/list`,
        component: React.lazy(() => import(`views/app-views/sites/orderSource/list`)),
    },
    {
        key: 'orderSource.edit',
        path: `${APP_PREFIX_PATH}/orderSource/:id/edit`,
        component: React.lazy(() => import(`views/app-views/sites/orderSource/edit`)),
    },
    {
        key: 'orderSource.create',
        path: `${APP_PREFIX_PATH}/orderSource/create`,
        component: React.lazy(() => import(`views/app-views/sites/orderSource/create`)),
    },
    // Sites order sources migration
    {
        key: 'orderSourceMigrations.list',
        path: `${APP_PREFIX_PATH}/orderSource/migration/list`,
        component: React.lazy(() => import(`views/app-views/sites/orderSourceMigrations/list`)),
    },
    {
        key: 'orderSourceMigrations.open',
        path: `${APP_PREFIX_PATH}/orderSource/migration/:id/open`,
        component: React.lazy(() => import(`views/app-views/sites/orderSourceMigrations/open`)),
    },
    {
        key: 'orderSourceMigrations.replace',
        path: `${APP_PREFIX_PATH}/orderSource/create`,
        component: React.lazy(() => import(`views/app-views/sites/orderSourceMigrations/replace`)),
    },
    // Sites payment systems
    {
        key: 'paymentSystems.list',
        path: `${APP_PREFIX_PATH}/paymentSystems/list`,
        component: React.lazy(() => import(`views/app-views/sites/paymentSystem/list`)),
    },
    {
        key: 'paymentSystems.edit',
        path: `${APP_PREFIX_PATH}/paymentSystems/:id/edit`,
        component: React.lazy(() => import(`views/app-views/sites/paymentSystem/edit`)),
    },
    {
        key: 'paymentSystems.create',
        path: `${APP_PREFIX_PATH}/paymentSystems/create`,
        component: React.lazy(() => import(`views/app-views/sites/paymentSystem/create`)),
    },
    // Sites countries
    {
        key: 'countries.list',
        path: `${APP_PREFIX_PATH}/countries/list`,
        component: React.lazy(() => import(`views/app-views/sites/countries/list`)),
    },
    {
        key: 'countries.edit',
        path: `${APP_PREFIX_PATH}/countries/:id/edit`,
        component: React.lazy(() => import(`views/app-views/sites/countries/edit`)),
    },
    {
        key: 'countries.create',
        path: `${APP_PREFIX_PATH}/countries/create`,
        component: React.lazy(() => import(`views/app-views/sites/countries/create`)),
    },
    // Sites currency
    {
        key: 'currency.list',
        path: `${APP_PREFIX_PATH}/currency/list`,
        component: React.lazy(() => import(`views/app-views/sites/currency/list`)),
    },
    {
        key: 'currency.edit',
        path: `${APP_PREFIX_PATH}/currency/:id/edit`,
        component: React.lazy(() => import(`views/app-views/sites/currency/edit`)),
    },
    {
        key: 'currency.create',
        path: `${APP_PREFIX_PATH}/currency/create`,
        component: React.lazy(() => import(`views/app-views/sites/currency/create`)),
    },
    // Sites prices
    {
        key: 'prices.list',
        path: `${APP_PREFIX_PATH}/prices/list`,
        component: React.lazy(() => import(`views/app-views/sites/prices/list`)),
    },
    {
        key: 'prices.edit',
        path: `${APP_PREFIX_PATH}/prices/:id/edit`,
        component: React.lazy(() => import(`views/app-views/sites/prices/edit`)),
    },
    {
        key: 'prices.create',
        path: `${APP_PREFIX_PATH}/prices/create`,
        component: React.lazy(() => import(`views/app-views/sites/prices/create`)),
    },
    // System users
    {
        key: 'users.list',
        path: `${APP_PREFIX_PATH}/users/list`,
        component: React.lazy(() => import(`views/app-views/system/users/list`)),
    },
    {
        key: 'users.edit',
        path: `${APP_PREFIX_PATH}/users/:id/edit`,
        component: React.lazy(() => import(`views/app-views/system/users/edit`)),
    },
    {
        key: 'users.create',
        path: `${APP_PREFIX_PATH}/users/create`,
        component: React.lazy(() => import(`views/app-views/system/users/create`)),
    },
    // System departments
    {
        key: 'departments.list',
        path: `${APP_PREFIX_PATH}/departments/list`,
        component: React.lazy(() => import(`views/app-views/system/departments/list`)),
    },
    {
        key: 'departments.edit',
        path: `${APP_PREFIX_PATH}/departments/:id/edit`,
        component: React.lazy(() => import(`views/app-views/system/departments/edit`)),
    },
    {
        key: 'departments.create',
        path: `${APP_PREFIX_PATH}/departments/create`,
        component: React.lazy(() => import(`views/app-views/system/departments/create`)),
    },
    // System departments
    {
        key: 'departments.list',
        path: `${APP_PREFIX_PATH}/departments/list`,
        component: React.lazy(() => import(`views/app-views/system/departments/list`)),
    },
    {
        key: 'departments.edit',
        path: `${APP_PREFIX_PATH}/departments/:id/edit`,
        component: React.lazy(() => import(`views/app-views/system/departments/edit`)),
    },
    {
        key: 'departments.create',
        path: `${APP_PREFIX_PATH}/departments/create`,
        component: React.lazy(() => import(`views/app-views/system/departments/create`)),
    },
    // System tasks
    {
        key: 'tasks.list',
        path: `${APP_PREFIX_PATH}/system/tasks/list`,
        component: React.lazy(() => import(`views/app-views/system/tasks/list`)),
    },
    // System departments
    {
        key: 'settings.basic',
        path: `${APP_PREFIX_PATH}/settings/basic`,
        component: React.lazy(() => import(`views/app-views/system/settings/basic`)),
    },
    {
        key: 'setting.proxy',
        path: `${APP_PREFIX_PATH}/settings/proxy`,
        component: React.lazy(() => import(`views/app-views/system/settings/proxy`)),
    },
    // System proxy rules
    {
        key: 'proxyRules.list',
        path: `${APP_PREFIX_PATH}/proxy/rules/list`,
        component: React.lazy(() => import(`views/app-views/system/proxyRules/list`)),
    },
    {
        key: 'proxyRules.edit',
        path: `${APP_PREFIX_PATH}/proxy/rules/:id/edit`,
        component: React.lazy(() => import(`views/app-views/system/proxyRules/edit`)),
    },
    {
        key: 'proxyRules.create',
        path: `${APP_PREFIX_PATH}/proxy/rules/create`,
        component: React.lazy(() => import(`views/app-views/system/proxyRules/create`)),
    },
    // System order status
    {
        key: 'orderStatus.list',
        path: `${APP_PREFIX_PATH}/orderStatus/list`,
        component: React.lazy(() => import(`views/app-views/system/orderStatus/list`)),
    },
    {
        key: 'orderStatus.edit',
        path: `${APP_PREFIX_PATH}/orderStatus/:id/edit`,
        component: React.lazy(() => import(`views/app-views/system/orderStatus/edit`)),
    },
    {
        key: 'orderStatus.create',
        path: `${APP_PREFIX_PATH}/orderStatus/create`,
        component: React.lazy(() => import(`views/app-views/system/orderStatus/create`)),
    },
    // System order status
    {
        key: 'proxyAuthType.list',
        path: `${APP_PREFIX_PATH}/proxyAuthType/list`,
        component: React.lazy(() => import(`views/app-views/system/proxyAuthType/list`)),
    },
    {
        key: 'proxyAuthType.edit',
        path: `${APP_PREFIX_PATH}/proxyAuthType/:id/edit`,
        component: React.lazy(() => import(`views/app-views/system/proxyAuthType/edit`)),
    },
    {
        key: 'proxyAuthType.create',
        path: `${APP_PREFIX_PATH}/proxyAuthType/create`,
        component: React.lazy(() => import(`views/app-views/system/proxyAuthType/create`)),
    },
    // System project guesser
    {
        key: 'projectGuesser',
        path: `${APP_PREFIX_PATH}/projectGuesser`,
        component: React.lazy(() => import(`views/app-views/system/projectGuesser`)),
    },
    // System front error report
    {
        key: 'frontErrorReport.list',
        path: `${APP_PREFIX_PATH}/front/error/report/list`,
        component: React.lazy(() => import(`views/app-views/system/frontErrorReport/list`)),
    },
    {
        key: 'frontErrorReport.edit',
        path: `${APP_PREFIX_PATH}/front/error/report/:id/edit`,
        component: React.lazy(() => import(`views/app-views/system/frontErrorReport/edit`)),
    },

    // Emilus templates
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/emilus/dashboards/default')),
    },
    {
        key: 'dashboard.analytic',
        path: `${APP_PREFIX_PATH}/dashboards/analytic`,
        component: React.lazy(() => import('views/app-views/emilus/dashboards/analytic')),
    },
    {
        key: 'dashboard.sales',
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        component: React.lazy(() => import('views/app-views/emilus/dashboards/sales')),
    },
    {
        key: 'apps',
        path: `${APP_PREFIX_PATH}/apps`,
        component: React.lazy(() => import('views/app-views/emilus/apps')),
    },
    {
        key: 'apps.mail',
        path: `${APP_PREFIX_PATH}/apps/mail/*`,
        component: React.lazy(() => import('views/app-views/emilus/apps/mail')),
    },
    {
        key: 'apps.chat',
        path: `${APP_PREFIX_PATH}/apps/chat/*`,
        component: React.lazy(() => import('views/app-views/emilus/apps/chat')),
    },
    {
        key: 'apps.calendar',
        path: `${APP_PREFIX_PATH}/apps/calendar`,
        component: React.lazy(() => import('views/app-views/emilus/apps/calendar')),
    },
    {
        key: 'apps.project',
        path: `${APP_PREFIX_PATH}/apps/project`,
        component: React.lazy(() => import('views/app-views/emilus/apps/project')),
    },
    {
        key: 'apps.project.list',
        path: `${APP_PREFIX_PATH}/apps/project/list`,
        component: React.lazy(() => import('views/app-views/emilus/apps/project/project-list/ProjectList')),
    },
    {
        key: 'apps.project.scrumboard',
        path: `${APP_PREFIX_PATH}/apps/project/scrumboard`,
        component: React.lazy(() => import('views/app-views/emilus/apps/project/scrumboard')),
    },
    {
        key: 'apps.ecommerce',
        path: `${APP_PREFIX_PATH}/apps/ecommerce`,
        component: React.lazy(() => import('views/app-views/emilus/apps/e-commerce')),
    },
    {
        key: 'apps.ecommerce.add-product',
        path: `${APP_PREFIX_PATH}/apps/ecommerce/add-product`,
        component: React.lazy(() => import('views/app-views/emilus/apps/e-commerce/add-product')),
    },
    {
        key: 'apps.ecommerce.edit-product',
        path: `${APP_PREFIX_PATH}/apps/ecommerce/edit-product/:id`,
        component: React.lazy(() => import('views/app-views/emilus/apps/e-commerce/edit-product')),
    },
    {
        key: 'apps.ecommerce.product-list',
        path: `${APP_PREFIX_PATH}/apps/ecommerce/product-list`,
        component: React.lazy(() => import('views/app-views/emilus/apps/e-commerce/product-list')),
    },
    {
        key: 'apps.ecommerce.orders',
        path: `${APP_PREFIX_PATH}/apps/ecommerce/orders`,
        component: React.lazy(() => import('views/app-views/emilus/apps/e-commerce/orders')),
    },
    {
        key: 'components.general',
        path: `${APP_PREFIX_PATH}/components/general`,
        component: React.lazy(() => import('views/app-views/emilus/components/general')),
    },
    {
        key: 'components.general.button',
        path: `${APP_PREFIX_PATH}/components/general/button`,
        component: React.lazy(() => import('views/app-views/emilus/components/general/button')),
    },
    {
        key: 'components.general.icon',
        path: `${APP_PREFIX_PATH}/components/general/icon`,
        component: React.lazy(() => import('views/app-views/emilus/components/general/icon')),
    },
    {
        key: 'components.general.typography',
        path: `${APP_PREFIX_PATH}/components/general/typography`,
        component: React.lazy(() => import('views/app-views/emilus/components/general/typography')),
    },
    {
        key: 'components.general',
        path: `${APP_PREFIX_PATH}/components/layout`,
        component: React.lazy(() => import('views/app-views/emilus/components/layout')),
    },
    {
        key: 'components.general.grid',
        path: `${APP_PREFIX_PATH}/components/layout/grid`,
        component: React.lazy(() => import('views/app-views/emilus/components/layout/grid')),
    },
    {
        key: 'components.general.layout',
        path: `${APP_PREFIX_PATH}/components/layout/layout`,
        component: React.lazy(() => import('views/app-views/emilus/components/layout/layout')),
    },
    {
        key: 'components.navigation',
        path: `${APP_PREFIX_PATH}/components/navigation`,
        component: React.lazy(() => import('views/app-views/emilus/components/navigation')),
    },
    {
        key: 'components.navigation.affix',
        path: `${APP_PREFIX_PATH}/components/navigation/affix`,
        component: React.lazy(() => import('views/app-views/emilus/components/navigation/affix')),
    },
    {
        key: 'components.navigation.breadcrumb',
        path: `${APP_PREFIX_PATH}/components/navigation/breadcrumb`,
        component: React.lazy(() => import('views/app-views/emilus/components/navigation/breadcrumb')),
    },
    {
        key: 'components.navigation.dropdown',
        path: `${APP_PREFIX_PATH}/components/navigation/dropdown`,
        component: React.lazy(() => import('views/app-views/emilus/components/navigation/dropdown')),
    },
    {
        key: 'components.navigation.menu',
        path: `${APP_PREFIX_PATH}/components/navigation/menu`,
        component: React.lazy(() => import('views/app-views/emilus/components/navigation/menu')),
    },
    {
        key: 'components.navigation.pagination',
        path: `${APP_PREFIX_PATH}/components/navigation/pagination`,
        component: React.lazy(() => import('views/app-views/emilus/components/navigation/pagination')),
    },
    {
        key: 'components.navigation.steps',
        path: `${APP_PREFIX_PATH}/components/navigation/steps`,
        component: React.lazy(() => import('views/app-views/emilus/components/navigation/steps')),
    },
    {
        key: 'components.data-entry',
        path: `${APP_PREFIX_PATH}/components/data-entry`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry')),
    },
    {
        key: 'components.data-entry.auto-complete',
        path: `${APP_PREFIX_PATH}/components/data-entry/auto-complete`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/auto-complete')),
    },
    {
        key: 'components.data-entry.cascader',
        path: `${APP_PREFIX_PATH}/components/data-entry/cascader`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/cascader')),
    },
    {
        key: 'components.data-entry.checkbox',
        path: `${APP_PREFIX_PATH}/components/data-entry/checkbox`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/checkbox')),
    },
    {
        key: 'components.data-entry.date-picker',
        path: `${APP_PREFIX_PATH}/components/data-entry/date-picker`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/date-picker')),
    },
    {
        key: 'components.data-entry.form',
        path: `${APP_PREFIX_PATH}/components/data-entry/form`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/form')),
    },
    {
        key: 'components.data-entry.input',
        path: `${APP_PREFIX_PATH}/components/data-entry/input`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/input')),
    },
    {
        key: 'components.data-entry.input-number',
        path: `${APP_PREFIX_PATH}/components/data-entry/input-number`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/input-number')),
    },
    {
        key: 'components.data-entry.mentions',
        path: `${APP_PREFIX_PATH}/components/data-entry/mentions`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/mentions')),
    },
    {
        key: 'components.data-entry.radio',
        path: `${APP_PREFIX_PATH}/components/data-entry/radio`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/radio')),
    },
    {
        key: 'components.data-entry.rate',
        path: `${APP_PREFIX_PATH}/components/data-entry/rate`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/rate')),
    },
    {
        key: 'components.data-entry.select',
        path: `${APP_PREFIX_PATH}/components/data-entry/select`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/select')),
    },
    {
        key: 'components.data-entry.slider',
        path: `${APP_PREFIX_PATH}/components/data-entry/slider`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/slider')),
    },
    {
        key: 'components.data-entry.switch',
        path: `${APP_PREFIX_PATH}/components/data-entry/switch`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/switch')),
    },
    {
        key: 'components.data-entry.time-picker',
        path: `${APP_PREFIX_PATH}/components/data-entry/time-picker`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/time-picker')),
    },
    {
        key: 'components.data-entry.transfer',
        path: `${APP_PREFIX_PATH}/components/data-entry/transfer`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/transfer')),
    },
    {
        key: 'components.data-entry.tree-select',
        path: `${APP_PREFIX_PATH}/components/data-entry/tree-select`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/tree-select')),
    },
    {
        key: 'components.data-entry.upload',
        path: `${APP_PREFIX_PATH}/components/data-entry/upload`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-entry/upload')),
    },
    {
        key: 'components.data-display',
        path: `${APP_PREFIX_PATH}/components/data-display`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display')),
    },
    {
        key: 'components.data-display.avatar',
        path: `${APP_PREFIX_PATH}/components/data-display/avatar`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/avatar')),
    },
    {
        key: 'components.data-display.badge',
        path: `${APP_PREFIX_PATH}/components/data-display/badge`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/badge')),
    },
    {
        key: 'components.data-display.calendar',
        path: `${APP_PREFIX_PATH}/components/data-display/calendar`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/calendar')),
    },
    {
        key: 'components.data-display.card',
        path: `${APP_PREFIX_PATH}/components/data-display/card`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/card')),
    },
    {
        key: 'components.data-display.carousel',
        path: `${APP_PREFIX_PATH}/components/data-display/carousel`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/carousel')),
    },
    {
        key: 'components.data-display.collapse',
        path: `${APP_PREFIX_PATH}/components/data-display/collapse`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/collapse')),
    },
    {
        key: 'components.data-display.comment',
        path: `${APP_PREFIX_PATH}/components/data-display/comment`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/comment')),
    },
    {
        key: 'components.data-display.descriptions',
        path: `${APP_PREFIX_PATH}/components/data-display/descriptions`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/descriptions')),
    },
    {
        key: 'components.data-display.empty',
        path: `${APP_PREFIX_PATH}/components/data-display/empty`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/empty')),
    },
    {
        key: 'components.data-display.list',
        path: `${APP_PREFIX_PATH}/components/data-display/list`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/list')),
    },
    {
        key: 'components.data-display.popover',
        path: `${APP_PREFIX_PATH}/components/data-display/popover`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/popover')),
    },
    {
        key: 'components.data-display.statistic',
        path: `${APP_PREFIX_PATH}/components/data-display/statistic`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/statistic')),
    },
    {
        key: 'components.data-display.table',
        path: `${APP_PREFIX_PATH}/components/data-display/table`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/table')),
    },
    {
        key: 'components.data-display.tabs',
        path: `${APP_PREFIX_PATH}/components/data-display/tabs`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/tabs')),
    },
    {
        key: 'components.data-display.tag',
        path: `${APP_PREFIX_PATH}/components/data-display/tag`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/tag')),
    },
    {
        key: 'components.data-display.timeline',
        path: `${APP_PREFIX_PATH}/components/data-display/timeline`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/timeline')),
    },
    {
        key: 'components.data-display.tooltip',
        path: `${APP_PREFIX_PATH}/components/data-display/tooltip`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/tooltip')),
    },
    {
        key: 'components.data-display.tree',
        path: `${APP_PREFIX_PATH}/components/data-display/tree`,
        component: React.lazy(() => import('views/app-views/emilus/components/data-display/tree')),
    },
    {
        key: 'components.feedback',
        path: `${APP_PREFIX_PATH}/components/feedback`,
        component: React.lazy(() => import('views/app-views/emilus/components/feedback')),
    },
    {
        key: 'components.feedback.alert',
        path: `${APP_PREFIX_PATH}/components/feedback/alert`,
        component: React.lazy(() => import('views/app-views/emilus/components/feedback/alert')),
    },
    {
        key: 'components.feedback.drawer',
        path: `${APP_PREFIX_PATH}/components/feedback/drawer`,
        component: React.lazy(() => import('views/app-views/emilus/components/feedback/drawer')),
    },
    {
        key: 'components.feedback.message',
        path: `${APP_PREFIX_PATH}/components/feedback/message`,
        component: React.lazy(() => import('views/app-views/emilus/components/feedback/message')),
    },
    {
        key: 'components.feedback.modal',
        path: `${APP_PREFIX_PATH}/components/feedback/modal`,
        component: React.lazy(() => import('views/app-views/emilus/components/feedback/modal')),
    },
    {
        key: 'components.feedback.notification',
        path: `${APP_PREFIX_PATH}/components/feedback/notification`,
        component: React.lazy(() => import('views/app-views/emilus/components/feedback/notification')),
    },
    {
        key: 'components.feedback.popconfirm',
        path: `${APP_PREFIX_PATH}/components/feedback/popconfirm`,
        component: React.lazy(() => import('views/app-views/emilus/components/feedback/popconfirm')),
    },
    {
        key: 'components.feedback.progress',
        path: `${APP_PREFIX_PATH}/components/feedback/progress`,
        component: React.lazy(() => import('views/app-views/emilus/components/feedback/progress')),
    },
    {
        key: 'components.feedback.result',
        path: `${APP_PREFIX_PATH}/components/feedback/result`,
        component: React.lazy(() => import('views/app-views/emilus/components/feedback/result')),
    },
    {
        key: 'components.feedback.skeleton',
        path: `${APP_PREFIX_PATH}/components/feedback/skeleton`,
        component: React.lazy(() => import('views/app-views/emilus/components/feedback/skeleton')),
    },
    {
        key: 'components.feedback.spin',
        path: `${APP_PREFIX_PATH}/components/feedback/spin`,
        component: React.lazy(() => import('views/app-views/emilus/components/feedback/spin')),
    },
    {
        key: 'components.other',
        path: `${APP_PREFIX_PATH}/components/other`,
        component: React.lazy(() => import('views/app-views/emilus/components/other')),
    },
    {
        key: 'components.other',
        path: `${APP_PREFIX_PATH}/components/other`,
        component: React.lazy(() => import('views/app-views/emilus/components/other')),
    },
    {
        key: 'components.other',
        path: `${APP_PREFIX_PATH}/components/other`,
        component: React.lazy(() => import('views/app-views/emilus/components/other')),
    },
    {
        key: 'components.other.anchor',
        path: `${APP_PREFIX_PATH}/components/other/anchor`,
        component: React.lazy(() => import('views/app-views/emilus/components/other/anchor')),
    },
    {
        key: 'components.other.backtop',
        path: `${APP_PREFIX_PATH}/components/other/backtop`,
        component: React.lazy(() => import('views/app-views/emilus/components/other/backtop')),
    },
    {
        key: 'components.other.config-provider',
        path: `${APP_PREFIX_PATH}/components/other/config-provider`,
        component: React.lazy(() => import('views/app-views/emilus/components/other/config-provider')),
    },
    {
        key: 'components.other.divider',
        path: `${APP_PREFIX_PATH}/components/other/divider`,
        component: React.lazy(() => import('views/app-views/emilus/components/other/divider')),
    },
    {
        key: 'components.chart',
        path: `${APP_PREFIX_PATH}/components/charts`,
        component: React.lazy(() => import('views/app-views/emilus/components/charts')),
    },
    {
        key: 'components.chart.apex-charts',
        path: `${APP_PREFIX_PATH}/components/charts/apex-charts`,
        component: React.lazy(() => import('views/app-views/emilus/components/charts/apex')),
    },
    {
        key: 'components.chart.chartjs',
        path: `${APP_PREFIX_PATH}/components/charts/chartjs`,
        component: React.lazy(() => import('views/app-views/emilus/components/charts/chartjs')),
    },
    {
        key: 'components.maps',
        path: `${APP_PREFIX_PATH}/components/maps`,
        component: React.lazy(() => import('views/app-views/emilus/components/maps')),
    },
    {
        key: 'components.maps.simple-map',
        path: `${APP_PREFIX_PATH}/components/maps/simple-map`,
        component: React.lazy(() => import('views/app-views/emilus/components/maps/simple-map')),
    },
    {
        key: 'login-1',
        path: `${APP_PREFIX_PATH}/login-1`,
        component: React.lazy(() => import('views/auth-views/authentication/login-1')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'login-2',
        path: `${APP_PREFIX_PATH}/login-2`,
        component: React.lazy(() => import('views/auth-views/authentication/login-2')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'register-1',
        path: `${APP_PREFIX_PATH}/register-1`,
        component: React.lazy(() => import('views/auth-views/authentication/register-1')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'register-2',
        path: `${APP_PREFIX_PATH}/register-2`,
        component: React.lazy(() => import('views/auth-views/authentication/register-2')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'forgot-password',
        path: `${APP_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'error-page-1',
        path: `${APP_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'error-page-2',
        path: `${APP_PREFIX_PATH}/error-page-2`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'pages',
        path: `${APP_PREFIX_PATH}/pages`,
        component: React.lazy(() => import('views/app-views/emilus/pages')),
    },
    {
        key: 'pages.profile',
        path: `${APP_PREFIX_PATH}/pages/profile`,
        component: React.lazy(() => import('views/app-views/emilus/pages/profile')),
    },
    {
        key: 'pages.invoice',
        path: `${APP_PREFIX_PATH}/pages/invoice`,
        component: React.lazy(() => import('views/app-views/emilus/pages/invoice')),
    },
    {
        key: 'pages.pricing',
        path: `${APP_PREFIX_PATH}/pages/pricing`,
        component: React.lazy(() => import('views/app-views/emilus/pages/pricing')),
    },
    {
        key: 'pages.faq',
        path: `${APP_PREFIX_PATH}/pages/faq`,
        component: React.lazy(() => import('views/app-views/emilus/pages/faq')),
    },
    {
        key: 'pages.setting',
        path: `${APP_PREFIX_PATH}/pages/setting/*`,
        component: React.lazy(() => import('views/app-views/emilus/pages/setting')),
    },
    {
        key: 'pages.user-list',
        path: `${APP_PREFIX_PATH}/pages/user-list`,
        component: React.lazy(() => import('views/app-views/emilus/pages/user-list')),
    },
    {
        key: 'docs.documentation',
        path: `${APP_PREFIX_PATH}/docs/documentation/*`,
        component: React.lazy(() => import('views/app-views/emilus/docs')),
    }
]