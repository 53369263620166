import sidenav from "./ru/ru_RU_sidenav.json"
import general from "./ru/ru_RU_general.json"
import label from "./ru/ru_RU_label.json"
import dropdown from "./ru/ru_RU_dropdown.json"
import message from "./ru/ru_RU_message.json"
import title from "./ru/ru_RU_title.json"
import experimental from "./ru/ru_RU_experimental.json"

const ru = {
    ...sidenav,
    ...general,
    ...label,
    ...dropdown,
    ...message,
    ...title,
    ...experimental
}

export default ru