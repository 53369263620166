import antdEnUS from 'antd/es/locale/en_US';
import antdUa from 'antd/es/locale/uk_UA';
import antdRus from 'antd/es/locale/ru_RU';
import en from './locales/en'
import ua from './locales/ua'
import ru from './locales/ru'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { USER_THEME_CONFIG } from 'configs/AppConfig';

export const resources = {
    en: {
        translation: en,
        antd: antdEnUS
    },
    ua: {
        translation: ua,
        antd: antdUa
    },
    ru: {
        translation: ru,
        antd: antdRus
    }
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: USER_THEME_CONFIG.locale,
    lng: USER_THEME_CONFIG.locale,
    interpolation: {
        escapeValue: false 
    }
})

export default i18n;