import axios from 'axios';
import {AUTH_TOKEN, AUTH_USER} from 'constants/AuthConstant';
import Utils from "../utils";

const ignoredPath = ["/auth", "/auth/login"]

const saveUnauthorizedLocation = () => {
    try {
        if (ignoredPath.indexOf(window.location?.pathname) === -1) {
            sessionStorage.setItem("unauthorizedLocation", window.location?.pathname + window.location?.search);
        }
    } catch (e) {

    }
}

axios.interceptors.request.use(config => {
    config.headers['react-app-id'] = sessionStorage?.reactJSId;
    config.headers['userFp'] = sessionStorage?.userFp;
    return config;
}, function (error) {
    return Promise.reject(error);
})

axios.interceptors.response.use(function (response) {
    // try {
    //     if (response.data.slice(0, 15) === "<!doctype html>") {
    //         localStorage.removeItem(AUTH_TOKEN);
    //         localStorage.removeItem(AUTH_USER)
    //         window.location.reload();
    //     } else {
    //         return response;
    //     }
    // } catch (e) {
    //     return response;
    // }
    return response;
}, function (error) {
    try {
        if(error.name === "CanceledError"){
            return
        }
        if (error.config.url === "/api/login") {
            return error;
        }
    } catch (e) {
        console.error("ApiService: Login error", e)
    }
    try {
        if (error.response.status === 401) {
            saveUnauthorizedLocation();
            // axios.get('/api/logout').then()
            localStorage.removeItem(AUTH_TOKEN);
            localStorage.removeItem(AUTH_USER);
            window.location.reload();
        }
    } catch (e) {

    }
    // if (error.response === undefined && sessionStorage.getItem("pageReloadedOnAuth") !== "1") {
    //     sessionStorage.setItem("pageReloadedOnAuth", "1")
    //     // console.error("ApiService: error, undefined", error)
    //     localStorage.removeItem(AUTH_TOKEN);
    //     localStorage.removeItem(AUTH_USER)
    //     window.location.reload();
    // } else {
        return error;
    // }
});

const apiService = {}

// apiService.telegramCreateChannel = (entity) => {
//     return axios.post('/api/telegram/channels', entity)
// }

// get all content
// {
//     country: true,
//     project: true,
//     orderSource: true,
//     orderStatus: true,
//     proxyAuthType: true,
//     paymentSystem: true,
//     currency: true,
//     client: true,
//     hostProvider: true,
//     mobileOperator: true,
//     proxyRule: true
// }
apiService.getContent = (obj) => {
    return axios.post('/api/content', obj)
}

apiService.getAppMD5 = () => {
    return axios.get('/api/system/app/md5')
}

apiService.reactAppClouse = () => {
    return axios.get('/api/system/reactapp/clouse')
}

apiService.telegramEditOrderMessage = (orderId, orderStatusId) => {
    return axios.post('/api/telegram/orders/status/edit', null, {params: {orderId, orderStatusId}})
}

apiService.telegramUpdateTelegramChannelIds = (managerId, systemId) => {
    return axios.post('/api/telegram/channels/id/update', null, {params: {managerId, systemId}})
}

apiService.telegramGetManagerChannelId = () => {
    return axios.get('/api/telegram/orders/channels/manager')
}

apiService.telegramGetSystemChannelId = () => {
    return axios.get('/api/telegram/orders/channels/system')
}

// apiService.telegramDeleteChannel = (id) => {
//     return axios.delete('/api/telegram/channels', {params: {id}})
// }
//
// apiService.telegramGetAllChannels = () => {
//     return axios.get('/api/telegram/channels')
// }
//
// apiService.telegramGetTargetList = () => {
//     return axios.get('/api/telegram/targets')
// }

apiService.systemConfigSetMainCurrency = value => {
    return axios.post('/api/system-config/currency/save', null, {params: {value}})
}

apiService.systemConfigGetMainCurrency = (param) => {
    return axios.get('/api/system-config/param', {params: {param}})
}

apiService.systemConfig = () => {
    return axios.get('/api/system-config/settings')
}

apiService.proxyRuleSave = (entity) => {
    return axios.post('/api/proxyRule/entity', entity)
}

apiService.proxyRuleCreate = (entity) => {
    return axios.post('/api/proxyRule', entity)
}

apiService.proxyRuleDelete = (id) => {
    return axios.delete('/api/proxyRule', {params: {id}})
}

apiService.proxyRuleGet = (id) => {
    return axios.get('/api/proxyRule', {params: {id}})
}

apiService.proxyRuleGetAll = () => {
    return axios.get('/api/proxyRule/all')
}

apiService.proxyRuleGetAllPage = (dto) => {
    return axios.post('/api/proxyRule/page', dto)
}

apiService.pricesGet = () => {
    return axios.get('/api/countries/prices/all')
}

apiService.pricesGetPage = (page, size, sort, search) => {
    return axios.get('/api/countries/prices/page', {params: {page: page - 1, size, sort, search}})
}

apiService.priceGet = (id) => {
    return axios.get('/api/countries/price', {params: {id}})
}

apiService.pricesSave = function (price, id) {
    return axios.post('/api/countries/prices/save', {price, id})
}

apiService.login = async function (username, password, rememberMe) {
    return await axios.post('/api/login', null, {params: {username, password, "remember-me": rememberMe}}).then((req) => {
        try {
            return req.status === 200;
        } catch (e) {
            return false
        }
    }).catch(() => {
        return false
    });
}

apiService.logout = async function () {
    return await axios.get('/api/logout').then(() => true).catch(() => false);
}

apiService.getUserInfo = function () {
    return axios.get('/api/users/info');
}

apiService.getAdminUser = function () {
    return axios.get('/api/users/adminUser');
}

apiService.createDefaultUser = function (email, password) {
    return axios.post('/api/users/createDefaultUser', {email, password});
}

apiService.usersSaveUiSettings = function (id, uiThemeColor, uiLanguage) {
    return axios.post('/api/users/ui/settings', {id, uiThemeColor, uiLanguage});
}

apiService.userClearNotifications = function (id) {
    return axios.post('/api/users/notifications/clear', {id});
}

apiService.usersGetAuthUser = function () {
    return axios.get('/api/users/authUser').then((res) => res.data).catch(() => null)
}

apiService.getUserDepartmentsCode = async function () {
    return await axios.get('/api/users/department-codes')
}

apiService.usersSaveOrderSourceSettings = function (settings) {
    return axios.post('/api/users/settings/orderSource', settings)
}

apiService.usersGetSettings = function (id) {
    return axios.get('/api/users/settings/sources', {params:{id}})
}

apiService.usersGetAuthUserAsync = async function () {
    return await axios.get('/api/users/authUser')
}

apiService.usersGetAll = function (page, size, sort, search) {
    return axios.get('/api/users', {params: {page: page - 1, size, sort, search}});
}

apiService.usersGetAllRolesNoPage = function () {
    return axios.get('/api/users/roles');
}

apiService.usersGetAllNoPage = function () {
    return axios.get('/api/users/no-page');
}

apiService.usersGetUser = function (id) {
    return axios.get('/api/users/profile', {params: {id}});
}

apiService.usersGetLoginDetails = function (userId) {
    return axios.get('/api/users/loginDetails', {params: {userId}});
}

apiService.usersCreate = function (user) {
    return axios.post('/api/users/createUser', {
        name: user.name,
        email: user.email,
        password: user.password,
        roles: []
    });
}

apiService.usersSessionDestroyed = function (userId) {
    return axios.post(`/api/users/${userId}/session/destroy`)
}

apiService.usersUploadAvatar = function (file, userId) {
    let formData = new FormData();
    formData.append("file", file)
    formData.append("userId", userId)
    return axios.post('/api/users/avatar/upload', formData);
}

apiService.usersDelete = function (id) {
    return axios.delete('/api/users', {params: {id}});
}

apiService.usersSaveProfile = function (userProfile) {
    return axios.post('/api/users/profile', userProfile);
}

apiService.usersSetNewPassword = function (newPassword) {
    return axios.post('/api/users/password', newPassword);
}

apiService.usersGetOnline = function () {
    return axios.get('/api/users/online');
}

apiService.hostsProvidersGetAll = function (dto, config) {
    return axios.post('/api/hostsProvider', dto, config)
}

apiService.hostsProvidersIpV6CreateTest = function (id) {
    return axios.get('/api/hostsProvider/ip6test', {params: {id}})
}

apiService.hostsProvidersGetAllReplace = function (hostsIds) {
    return axios.post('/api/hostsProvider/replace/selected', hostsIds)
}

apiService.hostsProvidersGetTerminalConnectionsPage = function (hostId, page, size) {
    return axios.post('/api/hostProvider/terminal', {hostId, page: page - 1, size})
}

apiService.hostsProvidersGetAllNoPage = function () {
    return axios.get('/api/hostsProvider/all')
}

apiService.proxyRuleGetAll = () => {
    return axios.get('/api/proxyRule/all')
}
apiService.countriesGetAll = function (page, size) {
    return axios.get('/api/countries', {params: {page: page - 1, size}});
}
apiService.orderStatusGetAll = function () {
    return axios.get('/api/orderStatus/all');
}
apiService.currencyGetAll = () => {
    return axios.get('/api/currency/all');
}

apiService.hostsProvidersGetReport = function (page, size, sort, search) {
    return axios.get('/api/reports/hosts', {params: {page: page - 1, size, sort, search}})
}

apiService.hostsProvidersGetIps = function (dto) {
    return axios.post('/api/hostsProvider/ips', dto)
}

apiService.clientActiveProxy = function (clientId, search, page, size, endDateRangeFrom, endDateRangeTo) {
    return axios.post(`/api/order/entity/ip/client`, {clientId, search, page: page - 1, size, endDateRangeFrom, endDateRangeTo})
}

apiService.clientActiveProxyFile = function (clientId, search, page, size, endDateRangeFrom, endDateRangeTo) {
    return axios.post(`/api/order/entity/ip/client/file`, {clientId, search, page: page - 1, size, endDateRangeFrom, endDateRangeTo})
}

apiService.hostsProvidersGetIpsAndClientsAmount = function (ids) {
    return axios.get('/api/hostsProvider/ips', {params: {ids}})
}

apiService.hostsProvidersGetAllActivePage = function (page, size, search, ignoreHost) {
    return axios.post('/api/hostsProvider/active', {page: page - 1, size, search, ignoreHost})
}

apiService.hostsProvidersGetAllActive = function () {
    return axios.get('/api/hostsProvider/active')
}

apiService.hostsProvidersGetAllReplacementsByCountries = function (entity) {
    return axios.post('/api/hostsProvider/by-country', entity)
}

apiService.hostsProvidersSetAdjust = function (id) {
    return axios.post('/api/hosterProvider/adjust', null, {params: {id}})
}

apiService.hostsProvidersSetDisabled = function (id) {
    return axios.post('/api/hosterProvider/disabled', null, {params: {id}})
}

apiService.hostsProvidersGetStatus = function (id) {
    return axios.get('/api/hostsProvider/server/status', {params: {id}})
}

apiService.hostsProvidersReplaceAll = async function (replaceHostsDto) {
    return await axios.post('/api/hostsProvider/replace', replaceHostsDto)
}

apiService.hostsProvidersGetAllById = function (entity) {
    return axios.post('/api/hostsProvider/all/by-ids', entity)
}

apiService.hostsProvidersReplacementsGetAll = function () {
    return axios.get('/api/hostsProvider/replacements')
}

apiService.hostsProvidersReplacementsDelete = function (id) {
    return axios.delete('/api/hostsProvider/replacements', {params: {id}});
}

apiService.hostsProvidersGetReplacementsById = function (id) {
    return axios.get('/api/hostsProvider/replacements/by-id', {params: {id}});
}

apiService.hostsProvidersGetReplacementEntityById = function (id) {
    return axios.get('/api/hostsProvider/replacements/entity', {params: {id}});
}

apiService.hostsProvidersMem = function (id) {
    return axios.get('/api/hostsProvider/server/mem', {params: {id}})
}

apiService.hostsProvidersUpdate = function (id) {
    return axios.get('/api/hostsProvider/server/update', {params: {id}})
}

apiService.hostsProvidersCreate = function (hostsProvider) {
    return axios.post('/api/hostsProvider/create', {name: hostsProvider.name});
}

apiService.hostsProvidersGetEntity = function (id) {
    return axios.get('/api/hostsProvider/entity', {params: {id}});
}

apiService.hostsProvidersModemCreate = function (hostId, countryId, aggregatorId, name, port, socksPort, modemPort, modemRotation, modemRebootLink, rotationMin, modemAutoRotation, operatorId,  id, disabled, notUseAdjust, modemType) {
    return axios.post('/api/proxyIpAddress/modem/create', {hostId, countryId, aggregatorId, name, port, socksPort, modemPort, modemRotation, modemRebootLink, modemRotationMin: rotationMin, modemAutoRotation, operatorId, id, disabled, notUseAdjust, modemType: modemType});
}

apiService.hostsProvidersModemHistory = function (dto) {
    return axios.post('/api/ip/modem/switch/history', dto);
}

apiService.hostsProvidersAggregatorCreate = function (hostId, name, ip, authLogin, countryId, login, password, authIp, weight, protocol, id) {
    return axios.post('/api/proxyIpAddress/aggregator/create', {hostId, name, ip, authLogin, countryId, login, password, authIp, weight, protocol, id});
}

apiService.hostsProvidersAggregatorAll = function (hostId) {
    return axios.get('/api/proxyIpAddress/aggregator/host', {params: {hostId}});
}

apiService.hostsProvidersAggregatorByIds = function (ids) {
    return axios.post('/api/proxyIpAddress/aggregator/ips', ids);
}

apiService.hostsProvidersAggregatorDelete = function (agrId) {
    return axios.delete('/api/proxyIpAddress/aggregator/host', {params: {agrId}});
}

apiService.hostsProvidersMobileAggregatorDelete = function (aggregatorId, confirm) {
    return axios.post('/api/proxyIpAddress/aggregator/delete',  {aggregatorId, confirm: confirm});
}

apiService.hostsProvidersGetAggregatorsByCountry = function (countryId) {
    return axios.get('/api/proxyIpAddress/aggregator/country',  {params: {countryId}});
}

apiService.hostsProvidersGetClients = function (id) {
    return axios.get('/api/hostsProvider/clients', {params: {id}});
}

apiService.hostsProvidersGetClientsEmailsTXT = function (id) {
    return axios.get('/api/hostsProvider/clients/emails/txt', {params: {id}});
}

apiService.hostsProvidersGetIdByNameContains = function (value) {
    return axios.get('/api/hostsProvider/entity/by-name-contains', {params: {value}});
}

apiService.hostsProvidersGetIdByFileName = function (fileName) {
    return axios.get('/api/hostsProvider/fileName', {params: {fileName}});
}

apiService.hostsProvidersGetConfigDto = function (id) {
    return axios.get('/api/hosterProvider/cfg', {params: {id}});
}

apiService.hostsProvidersSaveEntity = function (entity) {
    return axios.post('/api/hostsProvider/save', entity);
}

apiService.hostsProvidersDelete = function (id) {
    return axios.delete('/api/hostsProvider', {params: {id}});
}

apiService.hostsProvidersGetSsh = function (hostId) {
    return axios.get('/api/hostsProvider/ssh/get', {params: {hostId}});
}

apiService.hostsProvidersSaveSsh = function (dto) {
    return axios.post('/api/hostsProvider/ssh/save', dto);
}

apiService.hostsProvidersSshServerConnect = function (hostId) {
    return axios.get('/api/hostProvider/ssh/connect', {params: {hostId}});
}

apiService.hostsProviderHistoryList = function (dto) {
    return axios.post('/api/host/history/page', dto);
}

apiService.hostsProviderHistoryDetails = function (hostId) {
    return axios.get('/api/host/history/details', {params: {id: hostId}});
}

apiService.aggregatorsProxyAddressGetAll = function (dto) {
    return axios.get('/api/proxyIpAddress/aggregator/all', dto);
}

apiService.aggregatorsProxyAddressGetPage = function (dto) {
    return axios.post('/api/proxyIpAddress/aggregator/page', dto);
}


apiService.proxyIpAddressGetAllPost = function (page, size, sort, search, filterStartDate, filterEndDate, filterStartDateFrom, filterStartDateTo, filterEndDateFrom, filterEndDateTo) {
    return axios.post('/api/proxyIpAddress/filter', {page: page - 1, size, sort, search, filterStartDate, filterEndDate, filterStartDateFrom, filterStartDateTo, filterEndDateFrom, filterEndDateTo});
}

apiService.proxyIpAddressGetAll = function (page, size, sort, search) {
    return axios.get('/api/proxyIpAddress', {params: {page: page - 1, size, sort, search}});
}

apiService.proxyIpAddressGetReport = function (page, size, sort, search) {
    return axios.get('/api/proxyIpAddress/report', {params: {page: page - 1, size, sort, search}});
}

apiService.proxyIpAddressGetPickUp = function (page, size, sort, search, countryId, projectId, clientId, orderId) {
    return axios.get('/api/proxyIpAddress/pickUp', {
        params: {
            page: page - 1,
            size,
            sort,
            search,
            countryId,
            projectId,
            clientId,
            orderId
        }
    });
}

apiService.proxyIpAddressGetPickUpNext = function (page, size, sort, search, countryId, projectId, clientId, orderId, proxyType, operatorId, modemRotation, hideRecentClientIps) {
    return axios.post('/api/proxyIpAddress/pickUp/next', {
            page: page - 1,
            size,
            sort,
            search,
            countryId,
            projectId,
            clientId,
            orderId,
            proxyType,
            operatorId,
            modemRotation,
            hideRecentClientIps
    });
}

apiService.proxyIpAddressGetPickUpReplacementIps = function (dto) {
    return axios.post('/api/ipReplacement', dto);
}

apiService.proxyIpAddressGenIPv6ForOrder = function (orderId, countryId, projectId, count) {
    return axios.get('/api/proxyIpAddress/gen/ipv6', {params: {orderId, countryId, projectId, count}});
}

apiService.proxyIpAddressGetAllNotUsedForOrder = function (orderId, countryId, projectId, count) {
    return axios.get('/api/proxyIpAddress/unused', {params: {orderId, countryId, projectId, count}});
}

apiService.proxyIpAddressGetAllNotUsed = function () {
    return axios.get('/api/proxyIpAddress/unused/no-page');
}

apiService.proxyIpAddressCreate = function (ip, hostProviderId) {
    return axios.post('/api/proxyIpAddress/create', {ip, hostProviderId});
}

apiService.proxyIpAddressSaveIp = function (entity) {
    return axios.post('/api/proxyIpAddress/update', entity);
}

apiService.proxyIpAddressSave = function (entity) {
    return axios.post('/api/proxyIpAddress/entity', entity);
}

apiService.proxyIpAddressGetById = function (id) {
    return axios.get('/api/proxyIpAddress/entity', {params: {id}});
}

apiService.proxyIpAddressAggregatorById = function (id) {
    return axios.get('/api/proxyIpAddress/aggregator', {params: {id}});
}

apiService.proxyIpAddressDelete = function (id) {
    return axios.delete('/api/proxyIpAddress', {params: {id}});
}

apiService.proxyIpAddressFilterUpdate = function (id) {
    return axios.post(`/api/proxyIpAddress/filter/update/${id}`);
}

apiService.proxyIpAddressByClient = function (clientId, orderId) {
    return axios.get('/api/proxyIpAddress/client', {params: {clientId, orderId}});
}

apiService.proxyProjectsGetAll = function (dto) {
    return axios.post('/api/proxyProjects', dto);
}

apiService.proxyProjectsGetAllNoPage = function () {
    return axios.get('/api/proxyProjects/all');
}

apiService.proxyProjectsCreate = function (name, siteCode, hiddenForSearch) {
    return axios.post('/api/proxyProjects/create', {name, siteCode, hiddenForSearch});
}

apiService.proxyProjectsDelete = function (id) {
    return axios.delete('/api/proxyProjects', {params: {id}});
}

apiService.proxyProjectsGetEntity = function (id) {
    return axios.get('/api/proxyProjects/entity', {params: {id}});
}

apiService.proxyProjectsSave = function (entity) {
    return axios.post('/api/proxyProjects/entity', entity);
}

apiService.clientsGetAll = function (dto) {
    return axios.post('/api/clients', dto);
}

apiService.clientsGetEmailChangeHistory = function () {
    return axios.get('/api/clients/history/chnage-email');
}

apiService.clientsGetIpsHistory = function (clientId, page, size, sort, searchOrder, searchIp, docType) {
    return axios.get('/api/clients/ip-history', {params: {clientId, page: page - 1, size, sort, searchOrder, searchIp, docType}});
}

apiService.clientsGetIpsHistoryPost = function (clientId, page, size, sort, searchIp, docType) {
    return axios.post('/api/clients/ip-history', {clientId, page: page - 1, size, sort, searchIp, docType});
}


apiService.clientsGetIps6History = function (clientId) {
    return axios.get('/api/clients/ipv6-history', {params: {clientId}});
}

apiService.clientsGetIdByEmailContains = function (value) {
    return axios.get('/api/clients/by-email-contains', {params: {value}});
}

apiService.clientsCreate = function (name) {
    return axios.post('/api/clients/create', {name});
}

apiService.clientsDelete = function (id) {
    return axios.delete('/api/clients', {params: {id}});
}

apiService.clientsDeleteAll = function () {
    return axios.delete('/api/clients/delete/all');
}

apiService.clientsGetOrders = function (clientId) {
    return axios.get('/api/clients/orders', {params: {clientId}});
}

apiService.clientsGetFingerprintsPage = function (filter, page, size) {
    return axios.post('/api/client/fingerprint/page',  {page: page -1, size, filter: filter});
}

apiService.clientsGetFingerprints = function (clientId) {
    return axios.get('/api/clients/fingerprint', {params: {clientId}});
}

apiService.clientsGetFPTK = function (clientId) {
    return axios.get('/api/clients/fptk', {params: {id: clientId}});
}

apiService.clientsGetAnalise = function (clientId) {
    return axios.get('/api/clients/activity/analise', {params: {clientId}});
}

apiService.clientsEmailExist = function (email) {
    return axios.get('/api/clients/email/exist', {params: {email}});
}

apiService.clientsAssociation = function (clientEmail, associationEmail, orderSourceId) {
    return axios.post('/api/clients/association', {clientEmail, associationEmail, orderSourceId});
}

apiService.clientsGetOrdersPage = function (clientId, page, size, sort, search) {
    return axios.get('/api/clients/orders/page', {params: {clientId, page: page - 1, size, sort, search}});
}

apiService.clientsGetAuthData = function (id) {
    return axios.get('/api/clients/auth', {params: {id}});
}

apiService.clientsGetEntity = function (id) {
    return axios.get('/api/clients/entity', {params: {id}});
}

apiService.clientsSave = function (entity) {
    return axios.post('/api/clients/entity', entity);
}

apiService.clientsSetAllowAutoPickup = function (id) {
    return axios.get('/api/clients/allowAutoPickup', {params: {id}});
}

apiService.clientsAddAuthData = function (entity) {
    return axios.post('/api/clients/auth', entity);
}

apiService.clientsDeleteAuthData = function (id) {
    return axios.delete('/api/clients/auth', {params: {id}});
}

apiService.clientsGetAuthIp = function (clientId, orderSourceId) {
    return axios.get('/api/clients/auth/ip', {params: {clientId, orderSourceId}});
}

apiService.clientsSaveAuthData = function (entity) {
    return axios.post('/api/clients/auth/save', entity);
}

apiService.clientsSaveAuthIp = function (entity) {
    return axios.post('/api/clients/auth-ip/save', entity);
}

apiService.clientsAuthAllowIpAdd = function (id, ip, clientId) {
    return axios.post('/api/clientProxyAuth/allowIp', {id, ip, clientId});
}

apiService.clientsAuthAllowIpRemove = function (id, ip, clientId) {
    return axios.delete('/api/clientProxyAuth/allowIp', {params: {id, ip, clientId}});
}

apiService.clientsAuthBandlimGet = function (id) {
    return axios.get('/api/clients/auth/bandlim', {params: {id}});
}

apiService.clientsAuthSearch = function (search, page, size) {
    return axios.get('/api/clientProxyAuth/search', {params: {search, page: page - 1, size}});
}

apiService.clientsAuthBandlimSave = function (id, bandlimin, bandlimout) {
    return axios.post('/api/clients/auth/bandlim', {id, bandlimin, bandlimout});
}

apiService.clientsGetIpReplacement = function (clientId) {
    return axios.get('/api/clients/ipReplacement', {params: {clientId}});
}

apiService.clientsSetClearChangebacker = function (clientId) {
    return axios.post(`/api/clients/clear/chargebacker/${clientId}`);
}

apiService.orderSaveComment = function (id, comment) {
    return axios.post('/api/order/comment/save', {id, comment});
}

apiService.orderManualCreate = function (body) {
    return axios.post('/api/order/manual-create', body);
}

apiService.orderDeleteAllIp = function (id) {
    return axios.delete('/api/order/delete/all/order-ip', {params: {id}});
}

apiService.orderSendRequestToOrderSource = function (id) {
    return axios.post('/api/order/status/request', {id});
}

apiService.orderSourceSendRequest = function (source, number, authType) {
    return axios.get('/api/orderSource/req', {params: {source, number, authType}});
}

apiService.orderSourceGetAll = function (page, size, sort, search) {
    return axios.get('/api/orderSource', {params: {page: page - 1, size, sort, search}});
}

apiService.orderHistoryGetAllPage = function (historyId, page, size, sort, search) {
    return axios.post('/api/order/history', {historyId, page: page - 1, size, sort, search});
}

apiService.orderHistoryGetDetails = function (id) {
    return axios.get('/api/order/history/details', {params: {id}});
}

apiService.orderHistoryGetDetailsMassEndDateUpdate = function (id) {
    return axios.get('/api/order/history/details/MassEndDateUpdate', {params: {id}});
}

apiService.orderHistoryGetDetailsIPPickup = function (id) {
    return axios.get('/api/order/history/details/IPPickup', {params: {id}});
}

apiService.orderHistoryGetDetailsIPPickupV6 = function (id) {
    return axios.get('/api/order/history/details/IPPickupV6', {params: {id}});
}

apiService.orderHistoryGetDetailsOrderExtend = function (id) {
    return axios.get('/api/order/history/details/OrderExtend', {params: {id}});
}

apiService.orderHistoryGetDetailsExtensionRefund = function (id) {
    return axios.get('/api/order/history/details/ExtensionRefund', {params: {id}});
}

apiService.orderHistoryGetDetailsIpReplacement = function (id) {
    return axios.get('/api/order/history/details/IpReplacement', {params: {id}});
}

apiService.orderHistoryGetDetailsCreateOrderRefund = function (id) {
    return axios.get('/api/order/history/details/RefundCreate', {params: {id}});
}

apiService.orderHistoryGetDetailsUpdateOrderRefund = function (id) {
    return axios.get('/api/order/history/details/RefundUpdate', {params: {id}});
}

apiService.orderHistoryGetDetailsIpPickupTrigger = function (id) {
    return axios.get('/api/order/history/details/PickupTrigger', {params: {id}});
}

apiService.orderHistoryGetDetailsOrderStatusChange = function (id) {
    return axios.get('/api/order/history/details/OrderStatusChange', {params: {id}});
}

apiService.orderHistoryGetDetailsOrderProxyCountChange = function (id) {
    return axios.get('/api/order/history/details/ProxyCountChange', {params: {id}});
}

apiService.orderHistoryGetDetailsOrderIpDelete = function (id) {
    return axios.get('/api/order/history/details/OrderIpDelete', {params: {id}});
}

apiService.orderHistoryGetDetailsOrderMassIpDelete = function (id) {
    return axios.get('/api/order/history/details/OrderIpMassDelete', {params: {id}});
}

apiService.orderHistoryGetDetailsOrderMassIpDeleteRestore = function (id) {
    return axios.get('/api/order/history/details/OrderIpMassRestore', {params: {id}});
}

apiService.orderHistoryGetDetailsOrderRestoreDeletedIp= function (id) {
    return axios.get('/api/order/history/details/OrderIpRestore', {params: {id}});
}

apiService.orderHistoryGetDetailsOrderProjectChange = function (id) {
    return axios.get('/api/order/history/details/OrderProjectChange', {params: {id}});
}

apiService.orderHistoryGetDetailsOrderCountryChange = function (id) {
    return axios.get('/api/order/history/details/OrderCountryChange', {params: {id}});
}

apiService.orderHistoryGetDetailsOrderProxyTypeChange = function (id) {
    return axios.get('/api/order/history/details/OrderProxyTypeChange', {params: {id}});
}

apiService.orderHistoryGetDetailsOrderRotationChange = function (id) {
    return axios.get('/api/order/history/details/ModemRotationChange', {params: {id}});
}

apiService.orderHistoryGetDetailsOrderOperatorChange = function (id) {
    return axios.get('/api/order/history/details/MobileOperatorChange', {params: {id}});
}

apiService.OrderPaidStatusChange = function (id) {
    return axios.get(' /api/order/history/details/OrderPaidStatusChange', {params: {id}});
}

apiService.OrderProtocolChange = function (id) {
    return axios.get('/api/order/history/details/OrderProtocolChange', {params: {id}})
}

apiService.orderSourceMigrationsPage = function (page, size) {
    return axios.get('/api/orderSource/migration', {params: {page: page - 1, size}});
}

apiService.orderSourceMigrationEntity = function (id) {
    return axios.get(`/api/orderSource/migration/${id}`);
}

apiService.orderSourceMigrationProgress = function (id) {
    return axios.get(`/api/orderSource/migration/${id}/progress`);
}

apiService.orderSourceMigrationActionStart = function (migrationId) {
    return axios.get(`/api/orderSource/migration/resume`, {params: {migrationId}});
}

apiService.orderSourceMigrationActionPause = function (migrationId) {
    return axios.get(`/api/orderSource/migration/pause`, {params: {migrationId}});
}

apiService.orderSourceMigrationActionStop = function (migrationId) {
    return axios.get(`/api/orderSource/migration/stop`, {params: {migrationId}});
}

apiService.orderSourceChange = function (oldId, newId) {
    return axios.post(`/api/orderSource/change`, {oldId, newId})
}

apiService.orderSourceMigrationProxyPostPage = function (migrationId, page, size, search, sort, filterProxyStartDate, filterProxyEndDate, filterOrderDate, filterProxyStartDateFrom, filterProxyStartDateTo, filterProxyEndDateFrom, filterProxyEndDateTo, orderDateFrom, orderDateTo) {
    return axios.post('/api/orderSource/migration/proxy/filter', {migrationId, page: page - 1, size, search, sort, filterProxyStartDate, filterProxyEndDate, filterOrderDate, filterProxyStartDateFrom, filterProxyStartDateTo, filterProxyEndDateFrom, filterProxyEndDateTo, orderDateFrom, orderDateTo});
}

apiService.orderSourceMigrationProxyPage = function (migrationId, page, size) {
    return axios.get('/api/orderSource/migration/proxy', {params: {migrationId, page: page - 1, size}});
}

apiService.orderSourceMigrationsDelete = function (id) {
    return axios.delete('/api/orderSource/migration', {params: {id}});
}

apiService.orderSourceMigrationsFix = function (id) {
    return axios.post(`/api/orderSource/migration/${id}/fix`);
}

apiService.orderSourceMigrationsEntityUpdate = function (id) {
    return axios.post(`/api/orderSource/migration/entity/${id}`);
}

apiService.orderSourceGetAllNoPage = function () {
    return axios.get('/api/orderSource/all');
}

apiService.orderSourceCreate = function (name, token, socketToken) {
    return axios.post('/api/orderSource/create', {name, token, socketToken});
}

apiService.orderSourcePortsAdd = function (orderSourceId, proxy, socks) {
    return axios.post('/api/orderSource/ports/add', {orderSourceId, proxy, socks});
}

apiService.orderSourcePortsDelete = function (orderSourceId, proxy, socks) {
    return axios.post('/api/orderSource/ports/delete', {orderSourceId, proxy, socks});
}

apiService.orderSourceSetCountryCustomPrice = function (orderSourceId, countryId, price) {
    return axios.post('/api/orderSource/countries/price/set', {orderSourceId, countryId, price});
}

apiService.orderSourceDeleteCountryPrice = function (orderSourceId, countryId) {
    return axios.post('/api/orderSource/countries/price/delete', null, {params: {orderSourceId, countryId}});
}

apiService.orderSourceDelete = function (id) {
    return axios.delete('/api/orderSource', {params: {id}});
}

apiService.orderSourceDeleteClientAuth = function (id) {
    return axios.delete('/api/orderSource/client/auth', {params: {id}});
}

apiService.orderSourceGetEntity = function (id) {
    return axios.get('/api/orderSource/entity', {params: {id}});
}

apiService.orderSourceGetPriceList = function (id) {
    return axios.get('/api/orderSource/price-list', {params: {id}});
}

apiService.orderSourceSave = function (entity) {
    return axios.post('/api/orderSource/entity', entity);
}

apiService.orderExtendIP = function (type, orderId, newDate, days, orderIpIds) {
    return axios.post('/api/order/extend/ip', {type, orderId, newDate, days, orderIpIds});
}


apiService.jsonOrderGetByOrderSourceId = function (id, search = null) {
    return axios.get('/api/orderSource/json', {params: {id, search}});
}

apiService.orderUserEnter = function (id) {
    return axios.get('/api/order/user/enter', {params: {id}});
}

apiService.orderSetActive = function (id) {
    return axios.post('/api/order/user/set/active', {orderId: id})
}

apiService.orderUserOnline = function (id) {
    return axios.get('/api/order/user/online', {params: {id}});
}

apiService.orderUserLeave = function (id) {
    return axios.get('/api/order/user/leave', {params: {id}});
}

apiService.orderProxyTxt = function (id) {
    return axios.get('/api/order/proxy/txt', {params: {id}});
}

apiService.paymentSystemsGetAll = function (page, size, sort, search) {
    return axios.get('/api/paymentSystems', {params: {page: page - 1, size, sort, search}});
}

apiService.paymentSystemsGetAllNoPage = function () {
    return axios.get('/api/paymentSystems/all');
}

apiService.paymentSystemsCreate = function (name, code) {
    return axios.post('/api/paymentSystems/create', {name, code});
}

apiService.paymentSystemsDelete = function (id) {
    return axios.delete('/api/paymentSystems', {params: {id}});
}

apiService.paymentSystemsGetEntity = function (id) {
    return axios.get('/api/paymentSystems/entity', {params: {id}});
}

apiService.paymentSystemsSave = function (entity) {
    return axios.post('/api/paymentSystems/entity', entity);
}

apiService.countriesGetAll = function (page, size, sort, search) {
    return axios.get('/api/countries', {params: {page: page - 1, size, sort, search}});
}

apiService.countriesGetAllNoPage = function (config) {
    return axios.get('/api/countries/all', config);
}

apiService.countriesCreate = function (name, code) {
    return axios.post('/api/countries/create', {name, code});
}

apiService.countriesDelete = function (id) {
    return axios.delete('/api/countries', {params: {id}});
}

apiService.countriesGetEntity = function (id) {
    return axios.get('/api/countries/entity', {params: {id}});
}

apiService.countriesSave = function (entity) {
    return axios.post('/api/countries/entity', entity);
}

apiService.orderStatusGetPage = function (page, size, sort, search) {
    return axios.get('/api/orderStatus/page', {params: {page: page - 1, size, sort, search}});
}

apiService.orderStatusCreate = function (name, type, color) {
    return axios.post('/api/orderStatus/create', {name, type, color});
}

apiService.orderStatusDelete = function (id) {
    return axios.delete('/api/orderStatus', {params: {id}});
}

apiService.orderStatusGetEntity = function (id) {
    return axios.get('/api/orderStatus/entity', {params: {id}});
}

apiService.orderStatusSave = function (entity) {
    return axios.post('/api/orderStatus/entity', entity);
}

apiService.orderStatusGetIdByType = function (type) {
    return axios.get('/api/orderStatus/id', type);
}

apiService.proxyAuthTypeGetAll = function () {
    return axios.get('/api/proxyAuthType/all');
}

apiService.proxyAuthTypeCreate = function (name, code) {
    return axios.post('/api/proxyAuthType/create', {name, code});
}

apiService.proxyAuthTypeDelete = function (id) {
    return axios.delete('/api/proxyAuthType', {params: {id}});
}

apiService.proxyAuthTypeGetEntity = function (id) {
    return axios.get('/api/proxyAuthType', {params: {id}});
}

apiService.proxyAuthTypeSave = function (entity) {
    return axios.post('/api/proxyAuthType/entity', entity);
}

apiService.orderGetLastId = function () {
    return axios.get('/api/order/last-id');
}

// apiService.orderGetAll = function (dto, config) {
//     return axios.post('/api/order',  dto, config);
// }

apiService.getAllOrdersPage = function (dto, config) {
    return axios.post('/api/orders',  dto, config);
}

apiService.getOrdersPagination = function (dto, config) {
    return axios.post('/api/orders-pagination',  dto, config);
}

apiService.orderGetReport = function (entity) { //host, country, project, date, page, size, sort, search
    return axios.post('/api/reports/sales', entity); //{params: {host, country, project, date, page: page - 1, size, sort, search}}
}

apiService.orderGetReportSalesNew = function (entity) { //host, country, project, date, page, size, sort, search
    return axios.post('/api/reports/sales/new', entity); //{params: {host, country, project, date, page: page - 1, size, sort, search}}
}

apiService.orderGetReportSalesCustom = function (entity) { //host, country, project, date, page, size, sort, search
    return axios.post('/api/reports/sales/custom', entity); //{params: {host, country, project, date, page: page - 1, size, sort, search}}
}

apiService.orderGetIdByOrderNumberContains = function (value) {
    return axios.get('/api/order/by-order-number-contains', {params: {value}});
}

apiService.orderGetSubOrders = function (id) {
    return axios.get('/api/order/subOrders', {params: {id}});
}

apiService.orderGetPackageOrders = function (id) {
    return axios.get('/api/order/packageOrders', {params: {id}});
}

apiService.orderCreate = function (name, code) {
    return axios.post('/api/order/create', {name, code});
}

apiService.orderExtendRefund = function (refundOrderId, extendedOrderId, userId) {
    return axios.post('/api/order/extend/refund', {refundOrderId, extendedOrderId, userId});
}

apiService.orderHistoryExtendRefund = function (refundOrderId, historyDetailsId) {
    return axios.post('/api/order/history/extend/refund', {refundOrderId, historyDetailsId});
}

apiService.orderHistoryIPDeleteRestore = function (historyId) {
    return axios.get('/api/order/entity/ip/restore', {params: {historyId}});
}

apiService.orderHistoryIPMassDeleteRestore = function (historyId) {
    return axios.get('/api/order/restore/all/order-ip', {params: {historyId}});
}

apiService.orderChangeProtocol = function (orderId, proxyProtocol) {
    return axios.post('/api/order/change/protocol', {orderId, proxyProtocol})
}

apiService.orderEnableProxy = function (orderId) {
    return axios.post('/api/order/proxy/enable', {orderId});
}

apiService.orderDisableProxy = function (orderId) {
    return axios.post('/api/order/proxy/disable', {orderId});
}

apiService.orderPaidEnable = function (orderId) {
    return axios.post('/api/order/paid/enable', {orderId});
}

apiService.orderPaidDisable = function (orderId) {
    return axios.post('/api/order/paid/disable', {orderId});
}

apiService.orderReplaceEnableProxy = function (orderId) {
    return axios.post('/api/order/replace/proxy/enable', {orderId});
}

apiService.orderReplaceDisableProxy = function (orderId) {
    return axios.post('/api/order/replace/proxy/disable', {orderId});
}

apiService.orderReplaceIps = function (orderId, userId, oldIps, newIps, reason, countryId, projectId, modemRotationMin, modemOperator, switchModems, requestId, mobileOrderType) {
    return axios.post('/api/order/replaceIps', {orderId, userId, oldIps, newIps, reason, countryId, projectId, modemRotation: modemRotationMin, modemOperator, switchModems, requestId, mobileOrderType});
}

apiService.orderDeleteAll = function () {
    return axios.delete('/api/order/delete/all');
}

apiService.orderDelete = function (id) {
    return axios.delete('/api/order', {params: {id}});
}

apiService.newOrdersCount = function () {
    return axios.get('/api/order/new/count')
}

apiService.newRequestReplacementsCount = function () {
    return axios.get('/api/replacement/count')
}

apiService.newRequestTicketCount = function () {
    return axios.get('/api/tickets/count')
}

apiService.newRequestWithdrawalsCount = function () {
    return axios.get('/api/withdrawals/count')
}

apiService.newRequestRefundsCount = function () {
    return axios.get('/api/refunds/count')
}

apiService.orderGetEntity = function (id) {
    return axios.get('/api/order/entity', {params: {id}});
}

apiService.orderGetIPCount = function (id) {
    return axios.get('/api/order/ip/count', {params: {id}});
}

apiService.orderGetIpReplacement = function (orderId) {
    return axios.get('/api/order/ipReplacement', {params: {orderId}});
}

apiService.orderGetEntityIpFiltered = function (orderId, page, size, ip, sort) {
    return axios.post('/api/order/entity/ip/filtered', {orderId, page: page - 1, size, sort, ip});
}

apiService.orderGetEntityIp = function (id) {
    return axios.get('/api/order/entity/ip', {params: {id}});
}

apiService.orderGetEntityIpV6 = function (id) {
    return axios.get('/api/order/entity/ip6', {params: {id}});
}

apiService.orderGetEntityIpPage = function (id, page, size) {
    return axios.get('/api/order/entity/page/ip', {params: {id, page: page - 1, size,}});
}

apiService.orderSave = function (entity) {
    return axios.post('/api/order/entity', entity);
}

apiService.orderSaveIp = function (entity) {
    return axios.post('/api/order/entity/ip', entity);
}

apiService.orderIPDelete = function (id) {
    return axios.delete('/api/order/entity/ip', {params: {id}});
}

apiService.orderPickUpIp = function (entity) {
    return axios.post('/api/order/entity/pickUp/ip', entity);
}

apiService.getOrdersAnalyticsPerDay = function (params) {
    return axios.get('/api/order/analyticsPerDay', params);
}

apiService.systemGetNotification = function () {
    return axios.get('/api/notification');
}

apiService.currencyCreate = (name, code, symbol) => {
    return axios.post('/api/currency/create', {name, code, symbol});
}

apiService.currencyDelete = (id) => {
    return axios.delete('/api/currency', {params: {id}});
}

apiService.currencySave = (entity) => {
    return axios.post('/api/currency/save', entity);
}

apiService.currencyGetPage = (page, size, sort, search) => {
    return axios.get('/api/currency/page', {params:{page: page - 1, size, sort, search}});
}

apiService.currencyGetById = (id) => {
    return axios.get('/api/currency/entity', {params: {id}});
}

apiService.currencyGetExchange = (currency, cache) => {
    return axios.get('/api/currency/exchange', {params: {currency, cache}});
}

apiService.proxyIpAddressReplacementIps = function (entity) {
    return axios.post('/api/ipReplacement/replace', entity);
}

// apiService.ipReplacementGetById = (id) => {
//     return axios.get('/api/ipReplacement/entity', {params: {id}});
// }

apiService.ipReplacementGetById = (id) => {
    return axios.get('/api/ipReplacement/entity', {params: {id}});
}

apiService.requestReplacementGetById = (id) => {
    return axios.get('/api/replacement/get', {params: {id}});
}

apiService.hostProviderIpV6Defrag = (ipId) => {
    return axios.get('/api/proxyIpAddress/ipv6/freePorts', {params: {ipId}});
}

apiService.systemGetSettingsBasic = function () {
    return axios.get('/api/system-config/settings/basic');
}

apiService.systemSetSettingsBasic = function (entity) {
    return axios.post('/api/system-config/settings/basic', entity);
}

apiService.systemSetSettingsEncryptionInitialization = function () {
    return axios.get('/api/system/encryption/init');
}

apiService.systemGetSettingsProxy = () => {
    return axios.get('/api/system-config/settings/proxy');
}

apiService.systemSetSettingsProxy = function (entity) {
    return axios.post('/api/system-config/settings/proxy', entity);
}

apiService.tasksGetAllPage = function (page, size, sort, search) {
    return axios.get('/api/tasks', {params: {page: page - 1, size, sort, search}});
}

apiService.systemInitAll = function () {
    return axios.post('/api/system/init');
}

apiService.systemInitUsers = function () {
    return axios.post('/api/system/init/users');
}

apiService.systemIpUseValidate = function () {
    return axios.post('/api/system/ip/use/validate');
}

apiService.systemIPv6deleteUnUsed = function () {
    return axios.post('/api/system/ip6/delete/unused');
}

apiService.systemUsersAllLogout = function () {
    return axios.post('/api/users/logout/all');
}

apiService.reportsGetIpCoverage = (requestDto) => {
    return axios.post('/api/reports/ips', requestDto);
}

apiService.reportEngineExecute = (id) => {
    return axios.get('/api/report/engine/execute', { params: { id } });
}

apiService.reportEngineExecutePost = (id, name, baseCollectionName, stages, params) => {
    return axios.post('/api/report/engine/execute', { id, name, baseCollectionName, stages, params });
}

apiService.reportEngineCreate = (name, stages) => {
    return axios.post('/api/report/engine', { name, stages });
}

apiService.reportEngineGet = (id) => {
    return axios.get('/api/report/engine', { params: { id } });
}

apiService.reportEngineDelete = (id) => {
    return axios.delete('/api/report/engine', { params: { id } });
}

apiService.reportEngineGetParamsCollections = () => {
    return axios.get('/api/report/engine/params/collections');
}

apiService.reportEngineSave = (id, name, baseCollectionName, stages, params, accessRoles, totalAmounts) => {
    return axios.put('/api/report/engine', { id, name, baseCollectionName, stages, params, accessRoles, totalAmounts });
}

apiService.reportEngineGetAll = () => {
    return axios.get('/api/report/engine/all');
}

apiService.reportEnginePreview = (baseCollectionName, stages, params) => {
    return axios.post('/api/report/engine/preview', { baseCollectionName, stages, params });
}

apiService.ipFromText = (orderId, ips) => {
    return axios.post('/api/proxyIpAddress/parse', { orderId, ips });
}

apiService.mobileOperatorsPage = function (page, size, sort) {
    return axios.post('/api/operator/page', {page: page - 1, size, sort});
}

apiService.mobileOperatorsAll = function () {
    return axios.get('/api/operator/all');
}

apiService.mobileOperatorsCreate = function (name, tag) {
    return axios.post('/api/operator', {name, tag});
}

apiService.mobileOperatorsGetById = function (id) {
    return axios.get('/api/operator', { params: { id } });
}

apiService.mobileOperatorsDelete = function (id) {
    return axios.delete('/api/operator', { params: { id } });
}

apiService.mobileOperatorsSave = function (id, name, tag) {
    return axios.put('/api/operator', { id, name, tag });
}

apiService.consoleProxy = function (obj) {
    return axios.post('/api/proxyIpAddress/pickup/analize', obj)
}

apiService.ticketDepartamentCreate = function (departament) {
    return axios.post('/api/department/create', departament);
}

apiService.ticketDepartamentList = function () {
    return axios.get('/api/department/list');
}

apiService.ticketDepartamentGet = function (id) {
    return axios.get('/api/department/get', { params: { id } });
}

apiService.ticketDepartamentSave = function (obj) {
    return axios.put('/api/department/update', obj);
}

apiService.ticketDepartamentDelete = function (id) {
    return axios.delete('/api/department/delete', {params: {id}});
}

apiService.ticketPage = function (obj) {
    return axios.post('/api/tickets/page', obj);
}

apiService.ticketAddComment = function (obj) {
    return axios.post('/api/tickets/message/create', obj);
}

apiService.ticketCreate = function (obj) {
    return axios.post('/api/tickets/create', obj);
}

apiService.ticketGet = function (id) {
    return axios.get('/api/tickets/get', { params: { id } });
}

apiService.ticketMessages = function (ticketId) {
    return axios.get('/api/tickets/messages', { params: { ticketId } });
}

apiService.ticketHistory = function (ticketId) {
    return axios.get('/api/tickets/history', { params: { ticketId } });
}

apiService.ticketClose = function (id) {
    return axios.get('/api/tickets/close', { params: { id } });
}

apiService.ticketSave = function (obj) {
    return axios.put('/api/tickets/update', obj);
}

apiService.ticketDelete = function (id) {
    return axios.delete('/api/tickets/delete', {params: {id}});
}

apiService.ticketMessageNotifications = function () {
    return axios.get('/api/tickets/messages/notifications')
}

apiService.ticketNotificationsByDepartment = function () {
    return axios.get('/api/tickets/notifications')
}

apiService.ticketNotificationsByDepartmentWithPage = function (page, pageSize, ticketType) {
    return axios.get('/api/tickets/notifications/pageable', {params: {page: page - 1 , size: pageSize, type: ticketType}})
}

apiService.projectIndexGet = function () {
    return axios.get('/api/system/projects/index')
}

apiService.projectIndexReIndexEntity = function () {
    return axios.get('/api/system/projects/reCreateIndexEntity')
}

apiService.projectIndexReIndexWebService = function () {
    return axios.get('/api/system/projects/reindex')
}

apiService.projectIndexDeleteEntityAll = function () {
    return axios.delete('/api/system/projects/indexEntity')
}

apiService.getGeoByIP = function (ip) {
    return axios.get('/api/system/geoByIp', {params: {ip}})
}

apiService.getAllRequestReplacements = function (page, size, statusSort, filterMap, signal) {
    return axios.post('/api/replacement/page', {page: page - 1, size: size, sort: statusSort, filter: filterMap}, signal)
}

apiService.saveRequestReplacements = function (dto) {
    return axios.post('/api/replacement/save', dto)
}

apiService.getAllUpdateServers = function (dto) {
    return axios.post('/api/ota/host/get', dto)
}

apiService.getAllOTAModules = function () {
    return axios.get('/api/ota/module/get/all')
}

apiService.getOTAModule = function (id) {
    return axios.get('/api/ota/module/get', {params: {id}})
}

apiService.deleteOTAModule = function (id) {
    return axios.delete('/api/ota/module/delete', {params: {id}})
}

apiService.createOTAModules = function (dto) {
    return axios.post('/api/ota/module/create', dto)
}

apiService.updateOTAModules = function (dto) {
    return axios.put('/api/ota/module/update', dto)
}

apiService.createHostsOTAUpdate = function (dto) {
    return axios.post('/api/ota/host/create', dto)
}

apiService.updateHostsOTAUpdate = function (dto) {
    return axios.post('/api/ota/host/update', dto)
}

apiService.getAllHostsOTAUpdate = function (dto) {
    return axios.post('/api/ota/host/page', dto)
}

apiService.getHostsOTAUpdate = function (dto) {
    return axios.post('/api/ota/host/get', dto)
}

apiService.getHostsOTAUpdateExecute = function (id, resume) {
    return axios.get('/api/ota/host/execute', { params: { id, resume } })
}

apiService.getBandlimClientsInfo = function (dto) {
    return axios.post('/api/clientProxyAuth/bandlim/page', dto)
}


apiService.getAllHostModemRebootRequests = function (dto) {
    return axios.post('/api/ip/modem/reboot/requests', dto)
}

apiService.getAllPhantomHosts = function (dto) {
    return axios.post('/api/server/notfound/page', dto)
}

apiService.hostsProvidersMergeGetAll = function (dto, config) {
    return axios.post('/api/hostsProvider/merge/page', dto, config)
}

apiService.hostsProvidersMerge = function (dto) {
    return axios.post('/api/hostProvider/merge', dto)
}

apiService.getSubscribeForTicketsInTelegram = function () {
    return axios.get('/api/telegram/subscribe')
}

apiService.getHostProviderServerInfo = function (id) {
    return axios.get('/api/hostProvider/sys/comment', {params: {id}})
}

apiService.updateHostProviderServerInfo = function (dto) {
    return axios.post('/api/hostsProvider/sys/comment', dto)
}

apiService.getReports = function (dto) {
    return axios.post('/api/reports', dto)
}

apiService.getAllModems = function (dto) {
    return axios.post('/api/ip/modem/page', dto)
}

apiService.refundCreate = function (dto) {
    return axios.post('/api/refund', dto)
}

apiService.refundUpdate = function (dto) {
    return axios.put('/api/refund', dto)
}

apiService.getAllRefunds = function (page, size, sort, filter, signalConfig) {
    return axios.post('/api/refunds', {page: page -1, size, sort, filter}, signalConfig)
}

apiService.getRefund = function (id) {
    return axios.get('/api/refund', {params: {id}})
}

apiService.getOrderRefundsCounts = function (orderId) {
    return axios.get('/api/refund-count', {params: {orderId}})
}

apiService.getFrontErrorReportPage = function (page, size) {
    return axios.get("/api/front/error/report/list", { params: { page, size } });
};

apiService.getFrontErrorReportEntity = function (id) {
    return axios.get("/api/front/error/report", { params: { id } });
};

apiService.getOrderHistoryComment = function (id) {
    return axios.get("/api/order/history/details/OrderCommentChange", { params: { id } });
};

apiService.getAllAffiliateProgramWithdrawals = function (dto) {
    return axios.post("/api/withdrawals", dto);
};

apiService.getAffiliateProgramWithdrawal = function (id) {
    return axios.get("/api/withdrawal", { params: { id } });
};

apiService.updateAffiliateProgramWithdrawal = function (obj) {
    return axios.put("/api/withdrawal", obj);
};

apiService.hostsProviderReplacementIps = function (obj) {
    return axios.post("/api/hostProvider/replace/ip", obj);
};

apiService.getClientAuthHistory = function (clientId) {
    return axios.get("/api/clients/auth/history", { params: { clientId } });
};

apiService.getCurrentClientAuthHistory = function (authId) {
    return axios.get(`/api/clients/auth/history/${authId}`);
};

apiService.updateTicketSpecialist = function (obj) {
    return axios.put('/api/tickets/update/specialist', obj)
}

apiService.getClientSummary = function (clientId, orderSourceId) {
    return axios.get('/api/clients/summary', { params: { clientId, orderSourceId} })
}

apiService.getHostStatuses = function (obj) {
    return axios.post('/api/hostProvider/marks', obj)
}

apiService.getClientTrafficActivities = function (dto) {
    return axios.post('/api/clients/traffic', dto)
}

apiService.getClientTrafficActivitiesAll = function (dto) {
    return axios.post('/api/clients/traffic/all', dto)
}

apiService.getTotalDailyTrafficActivities = function (dto) {
    return axios.post('/api/clients/traffic/daily', dto)
}

apiService.setFrontErrorReport = function (reportObject) {
    return axios.post("/api/front/error/report", reportObject)
}

apiService.getAllServersProvider = function (dto) {
    return axios.post("/api/serverProvider/page", dto)
}

apiService.createServersProvider = function (dto) {
    return axios.post('/api/serverProvider', dto)
}

apiService.updateServersProvider = function (dto) {
    return axios.put('/api/serverProvider', dto)
}

apiService.getServerProvider = function (id) {
    return axios.get('/api/serverProvider', { params: { id } })
}

apiService.deleteServersProvider = function (id) {
    return axios.delete('/api/serverProvider', { params: { id } })
}

apiService.getServersProvidersAllNoPage = function () {
    return axios.get('/api/serverProvider/list', )
}

apiService.getProviderAccounts = function (providerId) {
    return axios.get('/api/serverAccount/list', {params: {providerId}})
}

apiService.createProviderAccount = function (dto) {
    return axios.post('/api/serverAccount', dto)
}

apiService.updateProviderAccount = function (dto) {
    return axios.put('/api/serverAccount', dto)
}


apiService.deleteProviderAccount = function (id) {
    return axios.delete('/api/serverAccount', {params: {id}})
}

apiService.modemSetDisable = function (id) {
    return axios.post(`/api/proxyIpAddress/modem/disabled`, null,{params: {id}})
}

apiService.modemSetNotUseAdjust = function (id) {
    return axios.post(`/api/proxyIpAddress/modem/adjust`, null,{params: {id}})
}

apiService.getAllProviderAccountHosts = function (dto) {
    return axios.post('/api/hostProvider/provider/page', dto)
}

apiService.createCheckerAnalysisActiveProxies = function (dto) {
    return axios.post('/api/clients/check/create', dto)
}

apiService.getAnalysisActiveProxiesData = function (id) {
    return axios.get('/api/clients/check', {params: {id}})
}

apiService.getAllAnalysisActiveProxiesPage = function (dto) {
    return axios.post('/api/clients/check/page', dto)
}

apiService.getTicketsOfEntity = function (id) {
    return axios.get('/api/tickets/source', {params: {id}})
}

apiService.getOrderConfirmPaid = function (orderId, paid) {
    return axios.post(`/api/order/confirmPaid`, null, {params: {orderId, paid}})
}

apiService.getOrderHistoryPaymentAmountChange = function (id) {
    return axios.get('/api/order/history/details/OrderPaymentAmountChange', {params: {id}})
}

apiService.orderConfirmPaymentAmountChange = function (orderId, paymentAmount, comment) {
    return axios.post('/api/order/confirmPaymentAmountChange', null,{params: {orderId, paymentAmount, comment}})
}

apiService.getChangeOrderAmount = (orderId, comment, files) => {
    let formData = new FormData();
    files.map(file => formData.append("files", file))
    formData.append("orderId", orderId)
    formData.append("comment", comment)
    return axios.post('/api/order/confirmPayment', formData)
}

// Cluster API section ---

apiService.getAllClustersPage = function (dto) {
    return axios.post('/api/cluster/page', dto)
}

apiService.createCluster = function (dto) {
    return axios.post('/api/cluster', dto)
}

apiService.getCluster = function (id) {
    return axios.get('/api/cluster', {params: {id}})
}

apiService.updateCluster = function (dto) {
    return axios.put('/api/cluster', dto)
}

apiService.deleteCluster = function (id) {
    return axios.delete('/api/cluster', {params: {id}})
}

apiService.createClusterWebHost = function (dto) {
    return axios.post('/api/webHost/children/create', dto)
}

apiService.getClusterWebHostList = function (obj) {
    return axios.post('/api/webHost/page', obj)
}

apiService.getWebHostOfCluster = function (id) {
    return axios.get('/api/webHost', {params: {id}})
}

apiService.deleteWebHostOfCluster = function (id) {
    return axios.delete('/api/webHost', {params: {id}})
}

apiService.getHostEntityTreeList = function (dto) {
    return axios.post('/api/ipAddress/tree', dto)
}

apiService.updateWebHostOfCluster = function (dto) {
    return axios.put('/api/webHost', dto)
}

apiService.getAllOperationSystems = function (dto) {
    return axios.post('/api/os/page', dto)
}

apiService.getAllOperationSystemsNoPage = function () {
    return axios.get('/api/os/all')
}

apiService.createOperationSystem = function (obj) {
    return axios.post('/api/os', obj)
}

apiService.deleteOperationSystem = function (id) {
    return axios.delete('/api/os', {params: {id}})
}

apiService.getOperationSystem = function (id) {
    return axios.get('/api/os', {params: {id}})
}

apiService.updateOperationSystem = function (obj) {
    return axios.put('/api/os', obj)
}

apiService.getShowUploadedFiles = function (id) {
    return axios.get('/api/order/showPaymentFile', {params: {fileId: id}})
}

apiService.getShowPaymentBinaryFiles = function (id) {
    return axios.get('/api/order/showPaymentFile/binary', {params: {fileId: id}})
}

apiService.getWebHostSshAuth = function (id) {
    return axios.get('/api/webHost/sshAuth', {params: {id}})
}

apiService.createWebHostSshAuth = function (dto) {
    return axios.post('/api/webHost/sshAuth', dto)
}

apiService.getAllOperationSystemScripts = function (dto) {
    return axios.post('/api/serverScript/page', dto)
}

apiService.createOperationSystemScripts = function (obj) {
    return axios.post('/api/serverScript', obj)
}

apiService.getOperationSystemScripts = function (id) {
    return axios.get('/api/serverScript', {params: {id}})
}

apiService.updateOperationSystemScripts = function (obj) {
    return axios.put('/api/serverScript', obj)
}

apiService.deleteOperationSystemScripts = function (id) {
    return axios.delete('/api/serverScript', {params: {id}})
}

apiService.getClusterServerInfo = function (id) {
    return axios.get('/api/server/info', {params: {hostId: id}})
}

apiService.updateClusterServerInfo = function (id) {
    return axios.get('/api/server/info/init', {params: {hostId: id}})
}

apiService.getSetupClusterServerInfo = function (dto) {
    return axios.post('/api/server/setup/status', dto)
}

apiService.getAllServerHistoryPage = function (dto) {
    return axios.post('/api/serverHistory', dto)
}

apiService.getServerHistory = function (id) {
    return axios.get('/api/serverHistory', {params: {id}})
}

//API Docker clusters section ---

apiService.getDockerContainers = function (hostId) {
    return axios.get('/api/docker/containers', {params: {hostId, all: true}})
}

apiService.getDockerContainerInspect = function (hostId, containerId) {
    return axios.get('/api/docker/containers/inspect', {params: {hostId, containerId}})
}

apiService.createDockerContainer = function (obj, hostId, name) {
    return axios.post('/api/docker/containers/create', obj, {params: {hostId, name}})
}

apiService.getProcessesDockerContainer = function (hostId, containerId) {
    return axios.get('/api/docker/containers/processes', {params: {hostId, containerId}})
}

apiService.getLogsDockerContainer = function (hostId, containerId, tail) {
    return axios.get('/api/docker/containers/logs', {params: {hostId, containerId, tail}})
}

apiService.getFileSystemDockerContainer = function (hostId, containerId) {
    return axios.get('/api/docker/containers/fs', {params: {hostId, containerId}})
}

apiService.getStatisticsDockerContainer = function (hostId, containerId) {
    return axios.get('/api/docker/containers/stats', {params: {hostId, containerId, stream: false}})
}

apiService.getWebSocketDockerContainer = function (hostId, containerId) {
    return axios.get('/api/docker/containers/ws', {params: {hostId, containerId}})
}

apiService.getResizeDockerContainer = function (hostId, containerId) {
    return axios.get('/api/docker/containers/resize', {params: {hostId, containerId}})
}

apiService.startDockerContainer = function (hostId, containerId) {
    return axios.get('/api/docker/containers/start', {params: {hostId, containerId}})
}

apiService.stopDockerContainer = function (hostId, containerId) {
    return axios.get('/api/docker/containers/stop', {params: {hostId, containerId}})
}

apiService.restartDockerContainer = function (hostId, containerId) {
    return axios.get('/api/docker/containers/restart', {params: {hostId, containerId}})
}

apiService.killDockerContainer = function (hostId, containerId) {
    return axios.get('/api/docker/containers/kill', {params: {hostId, containerId}})
}

apiService.updateDockerContainer = function (obj) {
    return axios.post('/api/docker/containers/update', obj)
}

apiService.renameDockerContainer = function (hostId, containerId, name) {
    return axios.get('/api/docker/containers/rename', {params: {hostId, containerId, name}})
}

apiService.pauseDockerContainer = function (hostId, containerId) {
    return axios.get('/api/docker/containers/pause', {params: {hostId, containerId}})
}

apiService.unpauseDockerContainer = function (hostId, containerId) {
    return axios.get('/api/docker/containers/unpause', {params: {hostId, containerId}})
}

apiService.deleteDockerContainer = function (hostId, containerId) {
    return axios.get('/api/docker/containers/remove', {params: {hostId, containerId}})
}

apiService.getDockerNetworks = function (hostId) {
    return axios.get('/api/docker/networks/list', {params: {hostId}})
}

apiService.createDockerNetwork = function (obj) {
    return axios.post('/api/docker/networks/create', obj)
}

apiService.deleteDockerNetwork = function (hostId, networkId) {
    return axios.get('/api/docker/networks/delete', {params: {hostId, networkId}})
}

apiService.getDockerNetworkInspect = function (hostId, networkId) {
    return axios.get('/api/docker/networks/inspect', {params: {hostId, networkId}})
}

apiService.getDockerNetworkConnect = function (obj) {
    return axios.post('/api/docker/networks/connect', obj)
}

apiService.getDockerNetworkDisconnect = function (obj) {
    return axios.post('/api/docker/networks/disconnect', obj)
}

apiService.getDockerNetworkPrune = function (hostId, networkId) {
    return axios.get('/api/docker/networks/prune', {params: {hostId, networkId}})
}

apiService.getDockerImages = function (hostId) {
    return axios.get('/api/docker/images/list', {params: {hostId, all: true}})
}

apiService.getDockerImageDeleteCache = function (hostId, imageId) {
    return axios.get('/api/docker/images/deleteCache', {params: {hostId, imageId}})
}

apiService.createDockerImage = function (obj, hostId) {
    return axios.post('/api/docker/images/create', obj, {params: {webHostId: hostId}})
}

apiService.getDockerImageInspect = function (hostId, name) {
    return axios.get('/api/docker/images/inspect', {params: {hostId, name}})
}

apiService.getDockerImageHistory = function (hostId, name) {
    return axios.get('/api/docker/images/history', {params: {hostId, name}})
}

apiService.getDockerImageTag = function (hostId, name) {
    return axios.get('/api/docker/images/tag', {params: {hostId, name}})
}

apiService.deleteDockerImage = function (hostId, imageId) {
    return axios.get('/api/docker/images/remove', {params: {hostId, name: imageId}})
}

apiService.getDockerImagesSearch = function (hostId, imageId) {
    return axios.get('/api/docker/images/search', {params: {hostId, imageId}})
}

apiService.getDockerImagesPrune = function (hostId, imageId) {
    return axios.get('/api/docker/images/prune', {params: {hostId, imageId}})
}

apiService.createDockerImageFromContainer = function (obj) {
    return axios.post('/api/docker/images/createFromContainer', obj)
}

apiService.getDockerExecuteCreate = function (obj) {
    return axios.post('/api/docker/exec/create', obj)
}

apiService.getDockerExecuteStart = function (obj) {
    return axios.post('/api/docker/exec/start', obj)
}

apiService.getDockerExecuteResize = function (hostId, dockerId) {
    return axios.get('/api/docker/exec/resize', {params: {hostId, dockerId}})
}

apiService.getDockerExecuteInspect = function (hostId, dockerId) {
    return axios.get('/api/docker/exec/inspect', {params: {hostId, dockerId}})
}

apiService.getDockerSystem = function (hostId) {
    return axios.get('/api/docker/system/info', {params: {hostId}})
}

apiService.getDockerSystemAuth = function (obj) {
    return axios.post('/api/docker/system/auth', obj)
}

apiService.getDockerSystemVersion = function (hostId) {
    return axios.get('/api/docker/system/version', {params: {hostId}})
}

apiService.getDockerSystemPing = function (hostId) {
    return axios.get('/api/docker/system/ping', {params: {hostId}})
}

apiService.getDockerSystemPingHead = function (hostId) {
    return axios.get('/api/docker/system/pingHead', {params: {hostId}})
}

apiService.getDockerSystemMonitor = function (hostId) {
    return axios.get('/api/docker/system/monitor', {params: {hostId}})
}

apiService.getDockerSystemDataUsage = function (hostId) {
    return axios.get('/api/docker/system/dataUsage', {params: {hostId}})
}

// API Docker HostProvider ---

apiService.getHostProviderDockerContainers = function (hostId) {
    return axios.get('/api/hostProvider/docker/containers', {params: {hostId, all: true}})
}

apiService.getHostProviderDockerContainerInspect = function (hostId, containerId) {
    return axios.get('/api/hostProvider/docker/containers/inspect', {params: {hostId, containerId}})
}

apiService.createHostProviderDockerContainer = function (obj, hostId, name) {
    return axios.post('/api/hostProvider/docker/containers/create', obj, {params: {hostId, name}})
}

apiService.getHostProviderDockerContainerProcesses = function (hostId, containerId) {
    return axios.get('/api/hostProvider/docker/containers/processes', {params: {hostId, containerId}})
}

apiService.getHostProviderDockerContainerLogs = function (hostId, containerId, tail) {
    return axios.get('/api/hostProvider/docker/containers/logs', {params: {hostId, containerId, tail}})
}

apiService.getHostProviderDockerContainerFileSystem = function (hostId, containerId) {
    return axios.get('/api/hostProvider/docker/containers/fs', {params: {hostId, containerId}})
}

apiService.getHostProviderStatisticsDockerContainer = function (hostId, containerId) {
    return axios.get('/api/hostProvider/docker/containers/stats', {params: {hostId, containerId, stream: false}})
}

apiService.startHostProviderDockerContainer = function (hostId, containerId) {
    return axios.get('/api/hostProvider/docker/containers/start', {params: {hostId, containerId}})
}

apiService.stopHostProviderDockerContainer = function (hostId, containerId) {
    return axios.get('/api/hostProvider/docker/containers/stop', {params: {hostId, containerId}})
}

apiService.restartHostProviderDockerContainer = function (hostId, containerId) {
    return axios.get('/api/hostProvider/docker/containers/restart', {params: {hostId, containerId}})
}

apiService.killHostProviderDockerContainer = function (hostId, containerId) {
    return axios.get('/api/hostProvider/docker/containers/kill', {params: {hostId, containerId}})
}

apiService.updateHostProviderDockerContainer = function (obj) {
    return axios.post('/api/hostProvider/docker/containers/update', obj)
}

apiService.renameHostProviderDockerContainer = function (hostId, containerId, name) {
    return axios.get('/api/hostProvider/docker/containers/rename', {params: {hostId, containerId, name}})
}

apiService.pauseHostProviderDockerContainer = function (hostId, containerId) {
    return axios.get('/api/hostProvider/docker/containers/pause', {params: {hostId, containerId}})
}

apiService.unpauseHostProviderDockerContainer = function (hostId, containerId) {
    return axios.get('/api/hostProvider/docker/containers/unpause', {params: {hostId, containerId}})
}

apiService.deleteHostProviderDockerContainer = function (hostId, containerId) {
    return axios.get('/api/hostProvider/docker/containers/remove', {params: {hostId, containerId}})
}

apiService.getHostProviderDockerNetworks = function (hostId) {
    return axios.get('/api/hostProvider/docker/networks/list', {params: {hostId}})
}

apiService.getHostProviderDockerNetworkInspect = function (hostId, networkId) {
    return axios.get('/api/hostProvider/docker/networks/inspect', {params: {hostId, networkId}})
}

apiService.getHostProviderDockerNetworkConnect = function (hostId, networkId) {
    return axios.post('/api/hostProvider/docker/networks/connect', {params: {hostId, networkId}})
}

apiService.getHostProviderDockerNetworkDisconnect = function (hostId, networkId) {
    return axios.post('/api/hostProvider/docker/networks/disconnect', {params: {hostId, networkId}})
}

apiService.getHostProviderDockerImages = function (hostId) {
    return axios.get('/api/hostProvider/docker/images/list', {params: {hostId, all: true}})
}

apiService.getHostProviderDockerImageInspect = function (hostId, name) {
    return axios.get('/api/hostProvider/docker/images/inspect', {params: {hostId, name}})
}

apiService.getHostProviderDockerImageHistory = function (hostId, name) {
    return axios.get('/api/hostProvider/docker/images/history', {params: {hostId, name}})
}

apiService.getHostProviderDockerSystem = function (hostId) {
    return axios.get('/api/hostProvider/docker/system/info', {params: {hostId}})
}

apiService.getHostProviderDockerSystemVersion = function (hostId) {
    return axios.get('/api/hostProvider/docker/system/version', {params: {hostId}})
}

apiService.getDockerConnect = function (hostId) {
    return axios.get('/api/docker/connect', {params: {hostId}})
}

apiService.getHostProviderDockerConnect = function (hostId) {
    return axios.get('/api/hostProvider/docker/connect', {params: {hostId}})
}

apiService.getDockerDisconnect = function (hostId) {
    return axios.get('/api/docker/disconnect', {params: {hostId}})
}

apiService.restartDocker = function (hostId) {
    return axios.get('/api/hostProvider/reboot-docker', {params: {hostId}})
}

apiService.projectGuesserSearch = function (search, count) {
    return axios.get('/api/search/projects/full', {params: {s: search, count}})
}

apiService.projectGuesserSearchDebug = function (search, count) {
    return axios.get('/api/search/projects/debug', {params: {s: search, count}})
}

apiService.turnOffNotificationsForBitrix = function (clientId, status) {
    return axios.post('/api/clients/turnOffNotificationsForBitrix', null, {params: {clientId, checkStatus: status}})
}

apiService.checkClientExistenceInBitrix = function (clientId) {
    return axios.post('/api/clients/checkClientExistenceInBitrix', null, {params: {clientId}})
}

apiService.getClientBitrixInfo = function (clientId) {
    return axios.post('/api/clients/getBitrixInfo', null, {params: {clientId}})
}

apiService.getClientSynchronizeWithBitrix = function (clientId) {
    return axios.post('/api/clients/synchronizeWithBitrix', null, {params: {clientId}})
}

apiService.getClientPipedriveSynchronize = function (clientId) {
    return axios.post('/api/clients/synchronizeWithPipedrive', null, {params: {clientId}})
}

apiService.getClientPipedriveInfo = function (clientId) {
    return axios.post('/api/clients/getPipedriveInfo', null, {params: {clientId}})
}

apiService.getClientTurnOffNotificationsForPipedrive = function (clientId, status) {
    return axios.post('/api/clients/turnOffNotificationsForPipedrive', null, {params: {clientId, checkStatus: status}})
}

apiService.getOrderNotes = function (id) {
    return axios.get('/api/order/notes/order-id', {params: {orderId: id}})
}

apiService.getNoteOfOrder = function (id) {
    return axios.get('/api/order/note', {params: {id}})
}

apiService.createOrderNote = function (obj) {
    return axios.post('/api/order/note', obj)
}

apiService.editOrderNote = function (obj) {
    return axios.put('/api/order/note', obj)
}

apiService.deleteOrderNote = function (id) {
    return axios.delete('/api/order/note', {params: {id}})
}

apiService.getOrderNotesCount = function (id) {
    return axios.get('/api/order/notes/order-id/count', {params: {orderId: id}})
}

apiService.getOrderNotesCount = function (id) {
    return axios.get('/api/order/notes/order-id/count', {params: {orderId: id}})
}

apiService.createDockerTemplate = function (obj) {
    return axios.post('/api/docker-template/create', obj)
}

apiService.getAllAnswerTemplates = function (lang, text) {
    return axios.get('/api/replacement/templates', {params: {lang, text}})
}

apiService.saveAnswerTemplates = function (obj) {
    return axios.put('/api/replacement/template', obj)
}

apiService.createAnswerTemplates = function (obj) {
    return axios.post('/api/replacement/template', obj)
}

apiService.deleteAnswerTemplates = function (id) {
    return axios.delete('/api/replacement/template', {params: {id}})
}

apiService.getSoftwarePackageList = function (dto) {
    return axios.post('/api/software-package/list', dto)
}

apiService.getSoftwarePackagePagination = function (dto) {
    return axios.post('/api/software-package/pagination', dto)
}

apiService.deleteSoftwarePackage = function (id) {
    return axios.delete('/api/software-package', {params: {id}})
}

apiService.getSoftwarePackage = function (id) {
    return axios.get('/api/software-package', {params: {id}})
}

apiService.getSoftwarePackageBriefInfo = function (id) {
    return axios.get('/api/software-package/brief-info', {params: {id: id}})
}

apiService.updateWebHostSoftwarePackage = function (hostId, packageId) {
    return axios.get('/api/webHost/update-package', {params: {hostId, packageId}})
}

apiService.updateHostProviderSoftwarePackage = function (hostId, packageId) {
    return axios.get('/api/hostProvider/update-package', {params: {hostId, packageId}})
}

apiService.deleteHostProviderSoftwarePackage = function (hostId, packageId) {
    return axios.get('/api/hostProvider/remove-package', {params: {hostId, packageId}})
}

apiService.getHostProviderFileLogsList = function (filePath) {
    return axios.get('/api/proxy-server/file-list', {params: {filePath}})
}

apiService.getHostProviderDownloadFileLogs = function (filePath) {
    return axios.get('/api/proxy-server/download-file', {params: {filePath}, responseType: "blob"})
}

apiService.getHostProviderDownloadFileLogsProgress = function (filePath, onDownloadProgress) {
    return axios.get('/api/proxy-server/download-file', {params: {filePath}, responseType: "blob", onDownloadProgress})
}

apiService.getHostProviderDeleteFileLogs = function (filePath) {
    return axios.get('/api/proxy-server/delete-file', {params: {filePath}})
}

apiService.getHostProviderUpdateList = function (dto) {
    return axios.post('/api/host/update-list', dto)
}

apiService.getHostProviderUpdateStatus = function (id) {
    return axios.get('/api/hostsProvider/software-status', {params: {id}})
}

apiService.updateDockerContainerLimits = function (obj) {
    return axios.post('/api/host/update-limits', obj)
}

apiService.getHostContainersEnvironments = function (hostId, hostType) {
    return axios.get('/api/hostProvider/get-env', {params: {hostId, hostType}})
}

apiService.deleteHostContainersEnvironments = function (obj) {
    return axios.post('/api/hostProvider/remove-env', obj)
}

apiService.updateHostContainersEnvironments = function (obj) {
    return axios.post('/api/hostProvider/update-env', obj)
}

apiService.getSystemConfigSettingCluster = function () {
    return axios.get('/api/system-config/settings/cluster')
}

apiService.saveSystemConfigSettingCluster = function (obj) {
    return axios.post('/api/system-config/settings/cluster', obj)
}

apiService.createClientRelation = function (firstAccountId, secondAccountId) {
    return axios.get('/api/clients/relations/createRelation', {params: {firstAccountId, secondAccountId}})
}

apiService.deleteClientRelation = function (clientId) {
    return axios.delete('/api/clients/relations/removeRelation', {params: {clientId}})
}

apiService.getClientRelations = function (clientId) {
    return axios.get('/api/clients/relations/getRelations', {params: {clientId}})
}

apiService.getSoftwareMassUpdate = function (packageId) {
    return axios.get('/api/host/mass-update', {params: {packageId}})
}

apiService.getSoftwareUpdateList = function (dto) {
    return axios.post('/api/host/update-list', dto)
}

apiService.getSoftwareStopMassUpdate = function (instanceId) {
    return axios.get('/api/host/stop-update', {params: {instanceId}})
}

apiService.getSoftwareMassUpdateCount = function (instanceId) {
    return axios.get('/api/host/update-count', {params: {instanceId}})
}

apiService.getSoftwareMassUpdatePage = function (dto) {
    return axios.post('/api/host/update-page', dto)
}

apiService.getSoftwareUpdateUnderway = function () {
    return axios.get('/api/host/update-underway')
}

apiService.getHostProviderReinstallContainer = function (hostId, containerName) {
    return axios.get('/api/hostProvider/reinstall-container', {params: {hostId, containerName}})
}

apiService.getProxySdkClientInfo = function (containerId) {
    return axios.get('/api/proxy-sdk/client-info', {params: {containerId}})
}

apiService.getProxySdkExecuteCall = function (status, dto) {
    return axios.post(`/api/proxy-sdk/execute-call/${status}`, dto)
}

apiService.getHostProviderUpdatingSoftwareLogs = function (hostId) {
    return axios.get('/api/hostProvider/installation-logs', {params: {hostId}})
}

apiService.getHostProviderLastSoftwareLog = function (hostId) {
    return axios.get('/api/hostProvider/last-log', {params: {hostId}})
}

apiService.getChangeSoftwareUpdateVisibilityStatus = function (dto) {
    return axios.post('/api/software-package/visibility-status', dto)
}

export default apiService;

export const apiWithSignalAbort = {
    getAllTicketMessages: function(ticketId) {
        return axios.get('/api/tickets/messages', { params: { ticketId }, cancelToken: cancelTokenHandlerObject[this.getAllTicketMessages.name].handleRequestCancellation().token});
    },
}

const cancelTokenHandlerObject = Utils.createCancelTokenHandler(apiWithSignalAbort)