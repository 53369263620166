import React, { createContext, useState } from 'react';

const defaultState = null

export const NotificationContext = createContext(defaultState);

export const NotificationProvider = ({ children }) => {
    const [keyContext, setKeyContext] = useState(null);
    const [notificationContext, setNotificationContext] = useState(null);
    const [ticketContextId, setTicketContextId] = useState(null);

    const state = {
        notificationContext,
        setNotificationContext,
        setKeyContext,
        keyContext,
        setTicketContextId,
        ticketContextId
    }

    // eslint-disable-next-line react/react-in-jsx-scope
    return <NotificationContext.Provider value={state}>{children}</NotificationContext.Provider>;
}

