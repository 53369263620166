import sidenav from "./ua/uk_UA_sidenav.json"
import label from "./ua/uk_UA_label.json"
import general from "./ua/uk_Ua_general.json"
import dropdown from "./ua/uk_UA_dropdown.json"
import message from "./ua/uk_UA_message.json"
import title from "./ua/uk_UA_title.json"
import experimental from "./ua/uk_UA_experimental.json"

const ua = {
    ...sidenav,
    ...label,
    ...general,
    ...dropdown,
    ...message,
    ...title,
    ...experimental
}

export default ua