import {lazy, Suspense, memo, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { ConfigProvider } from 'antd';
import Loading from 'components/shared-components/Loading';
import { lightTheme, darkTheme } from 'configs/ThemeConfig';
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import Routes from 'routes'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import LocaleData from 'dayjs/plugin/localeData'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import 'dayjs/locale/uk'
import 'dayjs/locale/ru'
import {getUser} from "../store/slices/userConfigSlice";
import {useThemeSwitcher} from "react-css-theme-switcher";
import {useLocation} from "react-router-dom";
import utils from "../utils";

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {
	const dispatch = useDispatch();
	const { switcher, themes } = useThemeSwitcher();
	const location = useLocation();

	dayjs.extend(customParseFormat)
	dayjs.extend(LocaleData)
	dayjs.extend(advancedFormat)
	dayjs.extend(weekday)
	dayjs.extend(localeData)
	dayjs.extend(weekOfYear)
	dayjs.extend(weekYear)

	const token = useSelector(state => state.auth.token);
	const userProfile = useSelector(state => state.theme.user);
	const blankLayout = useSelector(state => state.theme.blankLayout);

	const app_md5= localStorage.getItem("app_md5");

	const Layout = token && !blankLayout ? AppLayout : AuthLayout;

	const locale = useSelector(state => state.theme.locale);

	const direction = useSelector(state => state.theme.direction);

	const currentTheme = useSelector(state => state.theme.currentTheme);

	const currentAppLocale = resources[locale];

	useBodyClass(`dir-${direction}`);

	const themeConfig = currentTheme === 'light' ? {...lightTheme} : {...darkTheme}

	useEffect(() => {
		if (token || !app_md5){
			dispatch(getUser())
		}
	}, [token, app_md5, dispatch])

	useEffect(() => {
		dayjs().locale(locale === 'ua' ? 'uk' : locale)
		if(userProfile?.uiThemeColor) {
			switcher({ theme: themes[userProfile.uiThemeColor]});
		}

		// eslint-disable-next-line
	}, [locale, userProfile])

	useEffect(() => {
		utils.getClearPageData(location)
	}, [location]);

	return (
		<ConfigProvider theme={themeConfig} direction={direction} locale={currentAppLocale.antd}>
			<Suspense fallback={<Loading cover="content"/>}>
				<Layout>
					<Routes />
				</Layout>
			</Suspense>
		</ConfigProvider>
	)
}
// connect(mapStateToProps, mapDispatchToProps)(LoginForm)
export default memo(Layouts)