import React from "react";
import axios from "axios";
import Utils from "utils";

const getBrowserInfo = () => {
    const returnObj = {};
    const isSpa = (typeof window !== "undefined" && window.document && window.document.createElement);
    returnObj.renderMode = isSpa ? "SPA" : "SSR";
    if (isSpa) {
        returnObj.url = window.location.href;
        returnObj.innerHeight = window.innerHeight;
        returnObj.innerWidth = window.innerWidth;
        const _location = {};
        const _navigator = {};
        const _screen = {};
        try {
            for (const i in window.navigator) {
                if (!(typeof window.navigator[i] === "function")) {
                    _navigator[i] = window.navigator[i];
                }
            }
        } catch (ignored) { }
        try {
            for (const i in window.location) {
                if (!(typeof window.location[i] === "function")) {
                    _location[i] = window.location[i];
                }
            }
        } catch (ignored) { }
        try {
            for (const i in window.screen) {
                if (!(typeof window.screen[i] === "function")) {
                    _screen[i] = window.screen[i];
                }
            }
        } catch (ignored) { }
        returnObj.userAgent = window.navigator.userAgent;
        returnObj.navigator = _navigator;
        returnObj.location = _location;
        returnObj.screen = _screen;
    }
    return returnObj;
};

const fillErrorInReport = (error, report) => {
    report.errorName = error?.name;
    report.errorMessage = error?.message;
    report.errorStack = error?.stack;
};

const fillErrorInfoInReport = (errorInfo, report) => {
    report.errorComponentStack = errorInfo?.componentStack;
};

const fillExtendedFieldsInReport = (extendedFieldsObject, report) => {
    report.extendedFields = extendedFieldsObject;
};

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, reportId: null };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    handleClick = () => {
        Utils.clearSessionAndLocalStorage();
        Utils.clearBrowserCache();
        Utils.clearServiceWorkerCache();
        window && window.history.go(-2);
        const t = setTimeout(() => {
            clearTimeout(t)
            window.location.reload();
        }, 700);
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
            try {
                if (error?.name === "ChunkLoadError") {
                    if (typeof window !== "undefined" && window.document && window.document.createElement) {
                        const bSkip = localStorage.getItem("app-error-boundary-skip")
                        if (!bSkip) {
                            localStorage.setItem("app-error-boundary-skip", "ChunkLoadError");
                            Utils.clearSessionAndLocalStorage();
                            Utils.clearBrowserCache();
                            Utils.clearServiceWorkerCache();
                            window.location.reload();
                            return
                        } else {
                            localStorage.removeItem("app-error-boundary-skip")
                        }
                    }
                }
                const reportObject = getBrowserInfo();
                fillErrorInReport(error, reportObject);
                fillErrorInfoInReport(errorInfo, reportObject);
                fillExtendedFieldsInReport({}, reportObject);
                if(!Utils.isLocalhost()){
                    axios.post("/api/front/error/report", reportObject).then((req) => {
                        if (req?.status === 200) {
                            this.setState({ reportId: req?.data });
                        }
                    });
                }

            } catch (er) {
                // eslint-disable-next-line no-console
                console.error(er);
            }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{
                    display: "flex",
                    textAlign: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100hv"
                }}>
                    <h1 style={{margin: "24px"}}> Proxy CRM </h1>
                    <h2 style={{margin: "24px"}}>Ooops, Something went
                        wrong. {this.state.reportId ? `Report id: ${this.state.reportId}` : "Send report..."}</h2>
                    {this.state.reportId &&
                        <>
                            <h1 style={{margin: "24px"}}>We apologize, our specialists received your error!</h1>
                            <h2 style={{margin: "24px"}}>Do not worry, they will quickly subjugate everything now :)</h2>
                        </>
                    }
                    <button
                        style={{
                            margin: "24px",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #3e82f7",
                            backgroundColor: "#3e82f7",
                            color: "#ffffff",
                            fontSize: "18px"
                        }}
                        onClick={this.handleClick}
                    >
                        Try to get the app?
                    </button>
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
