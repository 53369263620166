import sidenav from "./en/en_US_sidenav.json"
import general from "./en/en_US_general.json"
import label from "./en/en_US_label.json"
import dropdown from "./en/en_US_dropdown.json"
import message from "./en/en_US_message.json"
import title from "./en/en_US_title.json"
import experimental from "./en/en_US_experimental"

const en = {
    ...sidenav,
    ...label,
    ...general,
    ...dropdown,
    ...message,
    ...title,
    ...experimental
}

export default en