import {Tag, Select, TreeSelect, Radio} from "antd";
import {setLocale, UseLocale} from "services/SetLocaleServise";

import {Link} from "react-router-dom";

import {APP_PREFIX_PATH} from "../../configs/AppConfig";

const {Option} = Select;
const { TreeNode } = TreeSelect;

export const treeSelectUserRolesData = [
    {
        title: 'SUPERUSER',
        value: 'SUPERUSER',
        key: 'SUPERUSER',
        disabled: true
    },
    {
        title: 'SYS_ADMIN',
        value: 'SYS_ADMIN',
        key: 'SYS_ADMIN',
    },
    {
        title: 'DEVOPS',
        value: 'DEVOPS',
        key: 'DEVOPS',
    },
    {
        title: 'MANAGER_ADMIN',
        value: 'MANAGER_ADMIN',
        key: 'MANAGER_ADMIN',
    },
    {
        title: 'ADMIN',
        value: 'ADMIN',
        key: 'ADMIN',
    },
    {
        title: 'MANAGER',
        value: 'MANAGER',
        key: 'MANAGER',
    },
    {
        title: 'INTEGRATOR',
        value: 'INTEGRATOR',
        key: 'INTEGRATOR',
    }
]

export const languagesCode = [
    {
        title: 'Ukrainian',
        value: 'ua',
        key: 'ua',
    },
    {
        title: 'Russian',
        value: 'ru',
        key: 'ru',
    },
    {
        title: 'English',
        value: 'en',
        key: 'en',
    },
    {
        title: 'French',
        value: 'fr',
        key: 'fr',
    },
    {
        title: 'Chinese',
        value: 'zh',
        key: 'zh',
    },
    {
        title: 'Spanish',
        value: 'es',
        key: 'es',
    },
    {
        title: 'Polish',
        value: 'pl',
        key: 'pl',
    },
    {
        title: 'Portuguese',
        value: 'pt',
        key: 'pt',
    },
    {
        title: 'German',
        value: 'de',
        key: 'de',
    },
    {
        title: 'Belarusian',
        value: 'by',
        key: 'by',
    },
    {
        title: 'Arabic',
        value: 'ar',
        key: 'ar',
    },
    {
        title: 'Azerbaijani',
        value: 'az',
        key: 'az',
    },
    {
        title: 'Bosnian',
        value: 'bs',
        key: 'bs',
    },
    {
        title: 'Czech',
        value: 'cz',
        key: 'cz',
    },
    {
        title: 'Danish',
        value: 'da',
        key: 'da',
    },
    {
        title: 'Estonian',
        value: 'et',
        key: 'et',
    },
    {
        title: 'Finnish',
        value: 'fi',
        key: 'fi',
    },
    {
        title: 'Croatian',
        value: 'hr',
        key: 'hr',
    },
    {
        title: 'Hungarian',
        value: 'hu',
        key: 'hu',
    },
    {
        title: 'Italian',
        value: 'it',
        key: 'it',
    },
    {
        title: 'Japanese',
        value: 'jp',
        key: 'jp',
    },
    {
        title: 'Georgian',
        value: 'ge',
        key: 'ge',
    },
    {
        title: 'Kazakh',
        value: 'kz',
        key: 'kz',
    },
    {
        title: 'Latvian',
        value: 'lv',
        key: 'lv',
    },
    {
        title: 'Lithuanian',
        value: 'lt',
        key: 'lt',
    },
    {
        title: 'Norwegian',
        value: 'no',
        key: 'no',
    },
    {
        title: 'Slovenian',
        value: 'sl',
        key: 'sl',
    },
    {
        title: 'Slovak',
        value: 'sk',
        key: 'sk',
    },
    {
        title: 'Albanian',
        value: 'al',
        key: 'al',
    },
    {
        title: 'Serbian',
        value: 'sr',
        key: 'sr'
    },
    {
        title: 'Swedish',
        value: 'se',
        key: 'se',
    },
    {
        title: 'Turkish',
        value: 'tr',
        key: 'tr',
    },
    {
        title: 'Vietnamese',
        value: 'vn',
        key: 'vn',
    },
    {
        title: 'Korean',
        value: 'ko',
        key: 'ko',
    },
    {
        title: 'Indonesian',
        value: 'id',
        key: 'id',
    },
    {
        title: 'Urdu (Pakistan)',
        value: 'ur',
        key: 'ur',
    },
    {
        title: 'Irish',
        value: 'ga',
        key: 'ga',
    }
]

export const dataTrafficUnits = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

export const toggleDropdown = [
    <Option key={"1"} value={true}><Tag color={"green"}>{setLocale("yes")}</Tag></Option>,
    <Option key={"2"} value={false}><Tag color={"red"}>{setLocale("no")}</Tag></Option>,
];

export const dateDropdown = [
    <Option key="D" value="D" >{setLocale("day")}</Option>,
    <Option key="W" value="W" >{setLocale("week")}</Option>,
    <Option key="M" value="M" >{setLocale("month")}</Option>
]

export const proxyTypeObjectsDropdown = [
    {title: <Tag color="blue">IPv4</Tag>, value: "IPv4", key: "IPv4"},
    {title: <Tag color="green">IPv6</Tag>, value: "IPv6", key: "IPv6"},
    {title: <Tag color="orange">MOBILE</Tag>, value: "MOBILE", key: "MOBILE"},
    {title: <Tag color="magenta">ISP</Tag>, value: "ISP", key: "ISP"},
    {title: <Tag color="black">R</Tag>, value: "R", key: "R"},
]

export const orderTypeObjectsDropdown = [
    {title: <Tag color="green">SINGLE</Tag>, value: "SINGLE", key: "SINGLE"},
    {title: <Tag color="blue">MULTI</Tag>, value: "MULTI", key: "MULTI"},
    {title: <Tag color="orange">MIXED</Tag>, value: "MIXED", key: "MIXED"},
]

export const reportDropdown= [
    <Radio key={"EXTEND"} value={"EXTEND"} className={"mr-3"}>{setLocale("renewals")}</Radio>,
    <Radio key={"REFUND"} value={"REFUND"} className={"mr-3"}>{setLocale("sidenav.refund")}</Radio>,
    <Radio key={"REPLACEMENT"} value={"REPLACEMENT"} className={"mr-3"}>{setLocale("replacements")}</Radio>,
    <Radio key={"CLIENT"} value={"CLIENT"} className={"mr-3"}>{setLocale("sidenav.clients")}</Radio>,
    <Radio key={"SALES"} value={"SALES"}>{setLocale("allSales")}</Radio>
]

export const refundStatusDropdown = [
    <Option key="OPEN" value="OPEN"><Tag color="blue">{setLocale("OPEN")}</Tag></Option>,
    <Option key="REIMBURSED" value="REIMBURSED"><Tag color="red">{setLocale("REIMBURSED")}</Tag></Option>,
    <Option key="RESOLVED" value="RESOLVED"><Tag color="green">{setLocale("RESOLVED")}</Tag></Option>
]

export const currencyDropdown = [
    <Option key={"0"} value={"RUB"}>Ruble</Option>,
    // <Option key={"1"} value={"GBP"}>Great Britain Pound</Option>,
    <Option key={"2"} value={"USD"}>US Dollar</Option>,
    // <Option key={"3"} value={"EUR"}>Euro</Option>,
    // <Option key={"4"} value={"UAH"}>Ukrainian Hryvnia</Option>,
]

export const ticketStatusDropdown = [
    <Option disabled={true} key={"NEW"} value={"NEW"}><Tag color="red">{setLocale("NEW")}</Tag></Option>,
    <Option disabled={true} key={"PROCESSING"} value={"PROCESSING"} ><Tag color="blue">{setLocale("PROGRESS")}</Tag></Option>,
    <Option disabled={true} key={"ANSWERED"} value={"ANSWERED"}><Tag color="green">{setLocale("ANSWERED")}</Tag></Option>,
    <Option key={"CLOSED"} value={"CLOSED"}><Tag color="black">{setLocale("CLOSED")}</Tag></Option>,
]

export const settingSoundDropdown = [
    <Option disabled key={"DISABLE"} value={"DISABLE"}><Tag color="red">Отключить</Tag></Option>,
    <Option key={"DEFAULT"} value={"notify-ticket-sound-def"}><Tag color="blue">Стандарт</Tag></Option>,
    <Option key={"ALEX-ENG"} value={"notify-ticket-alex-eng"}><Tag color="green">Саша (Анг.)</Tag></Option>,
    <Option key={"ALEX-ENG-BITCH"} value={"notify-ticket-alex-eng-bitch"}><Tag color="black">Саша (Анг. + мат)</Tag></Option>,
    <Option key={"ALEX-UKR"} value={"notify-ticket-alex-ukr"}><Tag color="black">Саша (Укр.)</Tag></Option>
]

export const approvedStatusDropdown = [
    <Option key={"NEW"} value={"NEW"}><Tag color="blue">{setLocale("WAITING")}</Tag></Option>,
    <Option key={"DECLINED"} value={"DECLINED"}><Tag color="red">{setLocale("DECLINED")}</Tag></Option>,
    <Option key={"APPROVED"} value={"APPROVED"}><Tag color="green">{setLocale("APPROVED")}</Tag></Option>,
]

export const userPermissionsAllowed = [
    <Option key={"ALLOWED"} value={"ALLOWED"}><Tag color={"green"}>{setLocale("ALLOWED")}</Tag></Option>,
    <Option key={"DISALLOW"} value={"DISALLOW"}><Tag color={"red"}>{setLocale("DISALLOW")}</Tag></Option>
]

export const hostSshCryptoAlgorithms = [
    <Option key="SHA1" value="SHA1"><Tag
        color="red">SHA1 {setLocale("deprecated")}</Tag></Option>,
    <Option key="SHA2" value="SHA2"><Tag
        color='green'>SHA2</Tag></Option>
]

export const authTypeOptions = [
    {id: "LOGIN", value: "LOGIN", name: "LOGIN"},
    {id: "IP", value: "IP", name: "IP"},
]

export const dropdown = {
    proxyType: [
        {value: "IPv4", color: "blue"},
        {value: "IPv6", color: "green"},
        {value: "MOBILE", color: "orange"},
        {value: "ISP", color: "magenta"},
        {value: "R", color: "black"}
    ],

    toggle: [
        {key: "Да", value: true, color: "green"},
        {key: "Нет", value: false, color: "red"}
    ],

    togglePaid: [
        {key: "Да", value: "yes", color: "green"},
        {key: "Нет", value: "no", color: "red"}
    ],

    protocol: [
        "tcp",
        "connect",
        "connect+",
        "http",
        "socks4",
        "socks4+",
        "socks5",
        "socks5+"
    ],

    documentType: [
        {value: "order", color: "geekblue", title: setLocale("order")},
        {value: "replacement", color: "red", title: setLocale("replacement")}
    ],

    taskStatus: [
        {value: "DONE", color: "green", title: setLocale("READY")},
        {value: "ERROR", color: "red", title: setLocale("FAILED")},
        {value: "BREAK", color: "red", title: setLocale("BREAK")},
        {value: "PROCESSING", color: "blue", title: setLocale("PROCESSING")}
    ],

    color: [
        {color: "red", value: "red", title: "Sample text"},
        {color: "magenta", value: "magenta", title: "Sample text"},
        {color: "volcano", value: "volcano", title: "Sample text"},
        {color: "lime", value: "lime", title: "Sample text"},
        {color: "orange", value: "orange", title: "Sample text"},
        {color: "gold", value: "gold", title: "Sample text"},
        {color: "green", value: "green", title: "Sample text"},
        {color: "cyan", value: "cyan", title: "Sample text"},
        {color: "blue", value: "blue", title: "Sample text"},
        {color: "geekblue", value: "geekblue", title: "Sample text"},
        {color: "purple", value: "purple", title: "Sample text"},
        {color: "black", value: "black", title: "Sample text"},
    ],

    toggleProtocol: [
        {value: "HTTP"},
        {value: "SOCKS"}
    ],

    approvedStatus: [
        {value: "NEW", color: "blue", title: setLocale("WAITING")},
        {value: "DECLINED", color: "red", title:setLocale("DECLINED")},
        {value: "APPROVED", color: "green", title: setLocale("APPROVED")},
    ],

    userPermissionsAllowed: [
        {value: "ALLOWED", color: "green", title: setLocale("ALLOWED")},
        {value: "DISALLOW", color: "red", title: setLocale("DISALLOW")},
    ],

    hostUpdateStatus: [
        {value: "FAILED", color: "red", title: setLocale("FAILED")},
        {value: "READY", color: "geekblue", title: setLocale("READY")},
        {value: "SUCCESS", color: "green", title: setLocale("SUCCESS")},
        {value: "PROCESSING", color: "gold", title: setLocale("PROCESSING")},
        {value: "NEW", color: "blue", title: setLocale("NEW")},
        {value: "NO_AUTH", color: "orange", title: setLocale("NO_AUTH")},
        {value: "CONNECTION_ERROR", color: "orange", title: setLocale("CONNECTION_ERROR")},
    ],

    hostOnlineStatus: [
        {value: "DOWN", color: "red", title: "DOWN"},
        {value: "ONLINE", color: "green", title: "ONLINE"},
    ],

    hostProxyCheck: [
        {value: "NOT_WORKING", color: "red", title: "NOT WORKING"},
        {value: "WORKING", color: "green", title: "WORKING"},
    ],

    analysisStatuses: [
        {value: "ERROR", color: "red", title: "ERROR"},
        {value: "WORKING", color: "green", title: "WORKING"},
        {value: "NEW", color: "blue", title: "NEW"},
    ],

    statusTreeObject: [
        {title: setLocale("FAILED"), color: "red", value: "error", key: "error"},
        {title: setLocale("READY"), color: "green", value: "done", key: "done"},
        {title: setLocale("WAITING"), color: "gold", value: "pending", key: "pending"},
        // {title: "Clear select", color: "black", value: "clear_select", key: "clear_select"}
    ],

    authType: [
        {id: "LOGIN", value: "LOGIN", name: "LOGIN"},
        {id: "IP", value: "IP", name: "IP"},
    ],

    term: [3, 7, 14, 30, 60, 90, 180, 360],

    modemType: [
        {value: "COMBINED", color: "geekblue", title: "COMBINED"},
        {value: "DEDICATED", color: "orange", title: "DEDICATED"},
        {value: "SHARED", color: "blue", title: "SHARED"},
    ],
    clusterType: [
        {value: "SHARED", color: "green", title: "SHARED"},
        {value: "OTHER", color: "blue", title: "OTHER"},
    ],
    serverEntityType: [
        {value: "ip", title: "IP"},
        {value: "network", title: "network"},
    ],
    os: [
        {value: "LINUX", title: "LINUX"},
        {value: "WINDOWS", title: "WINDOWS"},
        {value: "APPLE", title: "APPLE"},
    ],
    scriptType: [
        {value: "INFO", color: "#02b5af", title: "INFO"},
        {value: "UPGRADE", color: "#024c9c", title: "UPGRADE"},
        {value: "INSTALLATION", color: "#02b541", title: "INSTALLATION"},
        {value: "DOWNGRADE", color: "#9c8702", title: "DOWNGRADE"},
    ],
    programType: [
        {value: "OS", color: "orange", title: "OS"},
        {value: "DOCKER", color: "blue", title: "DOCKER"},
    ],
    categoryScript: [
        {value: "INITIAL", color: "blue", title: "INITIAL"},
        {value: "CUSTOM", color: "green", title: "CUSTOM"},
    ],
    architecture: [
        {value: "X86", title: "X86"},
        {value: "X64", title: "X64"},
        {value: "ARM64", title: "ARM64"},
    ],
    restartPolicies: [
        {value: "no", title: "None"},
        {value: "on-failure", title: "On failure"},
        {value: "always", title: "Always"},
        {value: "unless-stopped", title: "Unless stopped"},
    ],

    secondsDelay: [
        {title: '2 sec', value: 2000},
        {title: '3 sec', value: 3000},
        {title: '5 sec', value: 5000},
        {title: '10 sec', value: 10000},
        {title: '30 sec', value: 30000},
        {title: '60 sec', value: 60000}
    ]
}

export const GetDropdownData = (data, key) => {
    if(data){
        if(dropdown[data]){
            switch(data) {
                case 'protocol':
                    return dropdown[data].map((el) => <Option key={el} value={el}>{el.toUpperCase()}</Option>)
                case 'term':
                    return dropdown[data].map((el) => <Option key={el} value={el}>{el}</Option>)
                case 'togglePaid':
                    return dropdown[data].map((el) => <Option key={el.key} value={el.value}><Tag color={el.color}>{el.key}</Tag></Option>)
                case 'toggle':
                    return dropdown[data].map((el) => <Option key={el.key} value={el.value}><Tag color={el.color}>{el.key}</Tag></Option>)
                case 'modemType':
                    return dropdown[data].map((el) =>
                        <Option key={el.value} value={el.value}>
                            {el.value === "COMBINED"?<><Tag color={"blue"}>SHARED</Tag>+<Tag className={"ml-2"} color={"orange"}>DEDICATED</Tag></> : <Tag color={el.color}>{el.value}</Tag>}
                        </Option>)
                default:
                    return dropdown[data].map((el) =>
                        <Option key={el.value} value={el.value}>
                            {el.color ? <Tag color={el.color}>{el.title || el.value}</Tag> : (el.title || el.value)}
                        </Option>
                    )
            }
        }else{
            if(key && key==='multiple'){
                return data?.map((el) =>
                    el.color ?
                        <TreeNode
                            key={el.id}
                            value={el.id}
                            title={<Tag color={el.color}>{el.type ? setLocale(el.type, el?.name) : "Sample text"}</Tag>}
                        />
                        :
                        <TreeNode key={el.id} value={el.id} title={el.name} />
                )
            }else{
                if(typeof data === 'object' && data.length > 0){
                    return data?.map((el) =>
                        <Option key={el.id || el.key || el} value={el.id || el.key || el} disabled={el.disabled}>
                            {el.color ?
                                <Tag color={el.color}>{el.managerShow ? setLocale(el.type, el?.name) : (el.name || el.value)}</Tag>
                                :
                                (typeof el === 'string' ? el : el.name ||  <Link to={APP_PREFIX_PATH + `/serverProviders/${el.id}/edit`}>{el.domain}</Link> || "no data")
                            }
                        </Option>)
                }
            }
        }
    }else{
        return <Select.OptGroup>{UseLocale("noData")}</Select.OptGroup>
    }
}

